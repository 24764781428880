class ROUTES_NAME {
    static LOGIN = '/';
    static HOME = '/home';
    static ORDERS = '/orders';
    static PRODUCTS = '/products';
    static DISCOUNT = '/discount';
    static INSERT_PRODUCTS = '/insert-products';
    static MASS_ACTIONS = '/mass-actions-products';
    static FORGOT_PASSWORD = '/forgot-password';
    static SETTING = '/settings';
}

export default ROUTES_NAME
