import React from 'react';
import './graph.css';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/formatMoney';

const GraphComponent = ({ othersMonth, lastMonth, thisMonth }) => {

    const { t } = useTranslation();
    const totalValue = othersMonth + lastMonth + thisMonth;

    const valueGraphOtherMonth = {
        backgroundColor: '#323A50',
        width: ((othersMonth / 100000 * 5).toFixed(1) + '%')
    }

    const valueGraphLastMonth = {
        backgroundColor: '#434F72',
        width: ((lastMonth / 100000 * 5).toFixed(1) + '%')
    }

    const valueGraphThisMonth = {
        backgroundColor: '#4093C7',
        width: ((thisMonth / 100000 * 5).toFixed(1) + '%')
    }

    return (
        <section className='graph'>
            <div className='graph-bar'>
                <h1>{t('dashboard.chart.total-received')} - <span>{formatMoney(totalValue / 100)}</span></h1>
                <div className='mt-5'>
                    <div className='graph-bar-content'>
                        <div className='graph-bar-div'>
                            <div className='graph-bar-div-content' style={valueGraphOtherMonth}/>
                        </div>
                        <h3>20.000,00</h3>
                    </div>
                    <h2>{t('dashboard.chart.last-three-month')} - <span>{formatMoney(othersMonth / 100)}</span></h2>
                </div>
                <div>
                    <div className='graph-bar-content'>
                        <div className='graph-bar-div'>
                            <div className='graph-bar-div-content' style={valueGraphLastMonth}/>
                        </div>
                        <h3>20.000,00</h3>
                    </div>
                    <h2>{t('dashboard.chart.last-month')} - <span>{formatMoney(lastMonth / 100)}</span></h2>
                </div>
                <div>
                    <div className='graph-bar-content'>
                        <div className='graph-bar-div'>
                            <div className='graph-bar-div-content' style={valueGraphThisMonth}/>
                        </div>
                        <h3>20.000,00</h3>
                    </div>
                    <h2>{t('dashboard.chart.this-month')} - <span>{formatMoney(thisMonth / 100)}</span></h2>
                </div>
            </div>
        </section>
    );
};

export default GraphComponent;