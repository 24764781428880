import React from 'react';
import './notFound.css';
import navigateScreen from 'utils/navigateScreen';
import ROUTES_NAME from 'routes/routeName';

const NotFoundScreen = () => {
    
    const backToLogin = () => navigateScreen(ROUTES_NAME.LOGIN)

    return (
        <div className='not-found'>
            <h1>404</h1>
            <h2>Pagina não encontrada.</h2>
            <button onClick={backToLogin}>Voltar para Login</button>
        </div>
    )
}

export default NotFoundScreen