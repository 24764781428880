import React from 'react';
import './toggleButton.css'
import priceService from 'services/priceService';
import { showToast } from 'utils/showToast';
import { useTranslation } from 'react-i18next';

const ToggleButtonComponent = ({ nameRef, toggleValue, setToggleValues, refreshList }) => {
    const { t } = useTranslation();    
    
    const published = async (prices) => {
        let arrayId = [];

        prices.forEach(price => {
            arrayId.push(price.id);
        });

        if (arrayId.length > 0){
            try {
                await priceService.patch(arrayId, !toggleValue[nameRef]);
    
                setTimeout(() => {
                    refreshList();
                }, 500);
            }
            catch {
                showToast('error', t('toast-message.products.published.error'));
            }
        }
        else {
            showToast('error', t('toast-message.products.published.empty'));

            setTimeout(() => {
                refreshList();
            }, 1200);
        }
    }
    
    return (
        <label className='switch'>
            <input 
                type='checkbox'
                checked={toggleValue[nameRef]}
                onChange={(e) => {
                    [nameRef] == 'active' 
                    ? published(toggleValue.prices)
                    : setToggleValues( {...toggleValue, [nameRef]: !toggleValue[nameRef]})
                }}
                name={nameRef}/>
            <span className='slider round' />
        </label> 
    ) 
}

export default ToggleButtonComponent