import client from './client.js'
import ENDPOINTS from './endpoints.js'

const DiscountService = {
    postCouponDiscount: async (couponDiscountData) => {
        console.log(couponDiscountData);
        client.post(ENDPOINTS)
    },
} 

export default DiscountService