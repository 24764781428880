import React from 'react';
import './styles/loading.css'

const LoadingComponent = () => {
    return (
        <div className='content-loading'>
            <div className='lds-roller'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default LoadingComponent