import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showToast = ( type, message) => {
  toast[type](message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });
};

const ToastComponent = () => {
  return <ToastContainer />
}

export { showToast, ToastComponent };
