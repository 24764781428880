import './discountScreen.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast, ToastComponent } from 'utils/showToast.js';
import LoadingComponent from 'components/loadingComponent';
import NavHomeComponent from 'components/navHomeComponent';
import { Table } from 'react-bootstrap';
import image from 'assets/images/logo-short.svg'
import discountService from 'services/discountService';
import ActionProductButtonComponent from 'screens/product/components/actionProductButtonComponent';

const valueChange = (e, setData, data) => {
  let value = e.target.value.replace(/[^0-9]/g, '');

  value = Math.min(Math.max(parseFloat(value) || 0, 0), 100);

  setData({ ...data, discount: value });
}

const DiscountScreen = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [couponDiscountData, setCouponDiscountData] = useState({
    discount: '',
    code: '',
    initDatetime: '',
    expirationDatetime: ''
  });

  const [clientDiscountData, setClientDiscountData] = useState({
    discount: '',
    accountId: '',
    productPriceId: '',
  });

  const [productDiscountData, setProductDiscountData] = useState({
    discount: '',
    productPriceId: '',
  });

  const postCouponDiscount = () => {
    setIsLoading(true);
    try {
      if (
        couponDiscountData.discount > 0 &&
        couponDiscountData.code != "" &&
        couponDiscountData.expirationDatetime != "" &&
        couponDiscountData.initDatetime != ""
      ) {

        discountService.postCouponDiscount(couponDiscountData);
      } 
      else {
        showToast('error', t('toast-message.insert-products.screen.post.validate.error'));
      }
    }
    catch {
      showToast('error', 'Error');
    }
    finally {
      setIsLoading(false);
    }
  }

  return  (
    <NavHomeComponent
      screen={
        isLoading 
        ? <LoadingComponent /> 
        : <>
        <ToastComponent />
        <section className='section-discount'>
          <div className='div-discount'>
            <form className='form-discount'>
              <h2>{t('discount-screen.coupon-discount')}</h2>
              <div className='div-coupon'>
                <div className='input-discount'>
                  <label for='discount'>{t('discount-inputs.discount')}</label>
                  <input
                    type='text' 
                    name='discount'
                    value={couponDiscountData.discount}
                    onChange={(e) => valueChange(e, setCouponDiscountData, couponDiscountData)}
                    required />
                </div>
                <div className='input-discount'>
                  <label for='code'>{t('discount-inputs.code')}</label>
                  <input
                    type='text' 
                    name='code'
                    value={couponDiscountData.code}
                    onChange={(e) => setCouponDiscountData({ ...couponDiscountData, code: e.target.value})}
                    required />
                </div>
                <div className='input-discount'>
                  <label for='initDatetime'>{t('discount-inputs.init-date-time')}</label>
                  <input
                    type='date' 
                    name='initDatetime'
                    value={couponDiscountData.initDatetime}
                    onChange={(e) => setCouponDiscountData({ ...couponDiscountData, initDatetime: e.target.value})}
                    required />
                </div>
                <div className='input-discount'>
                  <label for='expirationDatetime'>{t('discount-inputs.expiration-date-time')}</label>
                  <input
                    type='date' 
                    name='expirationDatetime'
                    value={couponDiscountData.expirationDatetime}
                    onChange={(e) => setCouponDiscountData({ ...couponDiscountData, expirationDatetime: e.target.value})}
                    required />
                </div>
                <button disabled={isLoading} className='button-generate' type='button' onClick={() => postCouponDiscount()}>{t('buttons.coupon-generate')}</button>
              </div>
              <h2>{t('discount-screen.client-discount')}</h2>
              <div className='div-coupon'>
                <div className='input-discount'>
                  <label for='discount'>{t('discount-inputs.discount')}</label>
                  <input
                    type='text' 
                    name='discount'
                    value={clientDiscountData.discount}
                    onChange={(e) => valueChange(e, setClientDiscountData, clientDiscountData)}
                    required />
                </div>
                <div className='input-discount'>
                  <label for='client'>{t('discount-inputs.client')}</label>
                  <input
                    type='text' 
                    name='client'
                    value={clientDiscountData.accountId}
                    onChange={(e) => setClientDiscountData({ ...clientDiscountData, accountId: e.target.value})}
                    required />
                </div>
                <div className='input-discount'>
                  <label for='product'>{t('discount-inputs.product')}</label>
                  <input
                    type='text' 
                    name='product'
                    value={clientDiscountData.productPriceId}
                    onChange={(e) => setClientDiscountData({ ...clientDiscountData, productPriceId: e.target.value})}
                    required />
                </div>
                <button disabled={isLoading} className='button-generate' type='button'>{t('buttons.discount-generate')}</button>
              </div>
              <h2>{t('discount-screen.product-discount')}</h2>
              <div className='div-coupon'>
                <div className='input-discount'>
                  <label for='discount'>{t('discount-inputs.discount')}</label>
                  <input
                    type='text' 
                    name='discount'
                    value={productDiscountData.discount}
                    onChange={(e) => valueChange(e, setProductDiscountData, productDiscountData)}
                    required />
                </div>
                <div className='input-discount'>
                  <label for='product'>{t('discount-inputs.product')}</label>
                  <input
                    type='text' 
                    name='product'
                    value={productDiscountData.productPriceId}
                    onChange={(e) => setProductDiscountData({ ...productDiscountData, productPriceId: e.target.value})}
                    required />
                </div>
                <button disabled={isLoading} className='button-generate' type='button'>{t('buttons.discount-generate')}</button>
              </div>
            </form>
          </div>

          <div className='div-discount-created'>
            <div className='header-discount-created'>
              <button>{t('discount-screen.coupon')}</button>
              <button>{t('discount-screen.client')}</button>
            </div>
            <div className='div-card-coupon-discount'>
              <div className='card-discount'>
                <div className='code-coupon-discount'>
                  <div>
                    <p>{t('discount-inputs.code')}:</p>
                    <h2>P1C4</h2>
                  </div>
                  <div>
                    <p>{t('discount-inputs.discount')}:</p>
                    <h2>25%</h2>
                  </div>
                </div>
                <div className='dates-discount'>
                  <div>
                    <p>{t('discount-inputs.expiration-date-time')}:</p>
                    <h2>12/03/2024</h2>
                  </div>
                  <div>
                    <p>{t('discount-inputs.init-date-time')}:</p>
                    <h2>07/01/2024</h2>
                  </div>
                </div>
              </div>

              <div className='card-discount flex-column'>
                <div className='code-client-discount'>
                    <div>
                        <p>{t('discount-inputs.code')}:</p>
                        <h2>P1C4</h2>
                    </div>
                    <div>
                        <p>{t('discount-inputs.discount')}:</p>
                        <h2>25%</h2>
                    </div>
                    <div>
                        <p>{t('orders-table.cnpj')}:</p>
                        <h2>52.900.432/0001-52</h2>
                    </div>
                </div>
                <div className='discount-products-client'>
                    <div className='box-info-client'>
                        <div>
                            <p>{t('orders-table.client')}:</p>
                            <h2>Tiago Tinoco Martins</h2>
                        </div>
                        <div className='box-date'>
                            <p>{t('discount-inputs.expiration-date-time')}:</p>
                            <h2>12/03/2024</h2>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <img className='image-product-client' src={image} />
                        <div className='products-client'>
                            <div>
                                <p>{t('products-table.sku-short')}:</p>
                                <h2>2309831</h2>
                            </div>
                            <div>
                                <p>{t('products-table.name')}:</p>
                                <h2>Picanha congelado 5kg</h2>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container-table mt-3'>
          <Table className='productTable' id='table' responsive>
            <thead>
                <tr>
                    <th className='width-s'>{t('products-table.sku-short')}</th>
                    <th className='width-lg'>{t('products-table.name')}</th>
                    <th className='width-md'>{t('products-table.category')}</th>
                    <th className='width-s'>{t('products-table.price')}</th>
                    <th className='width-s'>{t('products-table.discount')}</th>
                    <th className='width-s'>{t('products-table.validate')}</th>
                    <th className='width-xs'>{t('products-table.actions')}</th>
                </tr>
            </thead>
            <tbody id='tbody'>
                {/* {
                    products.map((product) => (
                        <tr>
                            <td>{product.sku}</td>
                            <td><img className='image-product' src={product.imageUrl} alt='Imagem do produto' />{product.name}</td>
                            <td>{product.weight} kg</td>
                            <td>
                            {
                                product.prices.length == 0
                                ? ''
                                : `${formatMoney(minMaxPrices(product).minPrice)} - ${formatMoney(minMaxPrices(product).maxPrice)}`
                            }
                            </td>
                            <td>{product.boxQuantity} un</td>
                            <td>
                                <div className='d-flex'>
                                    <ul className='validates-column'>
                                        { product.shelfTypes.includes('GREEN') ? <li key={'tableGreen'} className='validate-green'></li> : null}
                                        { product.shelfTypes.includes('YELLOW') ? <li key={'tableYellow'} className='validate-yellow'></li> : null}
                                        { product.shelfTypes.includes('ORANGE') ? <li key={'tableOrange'} className='validate-orange'></li> : null}
                                        { product.shelfTypes.includes('RED') ? <li key={'tableRED'} className='validate-red'></li> : null}
                                    </ul>
                                </div>
                            </td>
                            <ToggleButtonComponent nameRef={'active'} toggleValue={product} setToggleValues={onSetProducts} refreshList={() => refresh()} />
                            <td><ActionProductButtonComponent actionId={product.id} refreshList={() => refresh()} skuProduct={product.sku} /></td>
                        </tr>
                    ))
                } */}
                <tr>
                  <td>123123</td>
                  <td><img className='image-product' src={image}/> Picanha congelada 5kg</td>
                  <td>Bovinos</td>
                  <td>R$ 54,90</td>
                  <td>45%</td>
                  <td>07/03/2024</td>
                  <td><ActionProductButtonComponent discountScreen={true} skuProduct={'123123'}/></td>
                </tr>
            </tbody>
          </Table>
        </section>
      </>
      }
    />
  );
};

export default DiscountScreen;