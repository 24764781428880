import * as XLSX from 'xlsx';

function downloadExcel(fileName, titles, data) {
    const dataArray = data.map(item => Object.values(item));

    const spreadsheet = XLSX.utils.book_new();

    // Add data directly to the spreadsheet using aoa_to_sheet
    const page = XLSX.utils.aoa_to_sheet([titles, ...dataArray]);
    XLSX.utils.book_append_sheet(spreadsheet, page, 'Dados');

    // create file
    XLSX.writeFile(spreadsheet, `${fileName}.xlsx`);
};

export default downloadExcel