const AUTH = '/auth';
const INDUSTRIES = '/industries';
const ACCOUNTS = '/accounts';

class ENDPOINTS {
    static REFRESH_TOKEN = `${AUTH}/refresh-token/industries`
    static SIGN_IN = `${AUTH}/signin/industry`;
    static PRODUCT = `${INDUSTRIES}/products`;
    static PRODUCT_BATCH = `${INDUSTRIES}/products/batch`;
    static PRODUCT_ID = `${INDUSTRIES}/product/`;
    static PRICES = `${INDUSTRIES}/prices`;
    static PRICES_ID = `${INDUSTRIES}/prices/`;
    static CATEGORY = `${INDUSTRIES}/categories`;
    static CATEGORY_POST = `${INDUSTRIES}/category`;
    static ORDER = `${INDUSTRIES}/orders`;
    static ORDER_STATUS = `${INDUSTRIES}/orders?status=`;
    static ORDER_ACCOUNT_ID = `${INDUSTRIES}/orders?accountId=`;
    static ORDER_REPORT = `${INDUSTRIES}/orders/report`;
    static ORDER_ID = `${INDUSTRIES}/order/`;
    static INDUSTRY_PROFILE = `${ACCOUNTS}/profile/industry`;
}

export default ENDPOINTS