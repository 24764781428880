import React, { useState } from 'react';
import ROUTES_NAME from 'routes/routeName';
import './actionProductButton.css';
import { IoMdTrash, IoMdCreate  } from 'react-icons/io';
import navigateScreen from 'utils/navigateScreen';
import DialogProductComponent from './dialogProductComponent';

const ActionProductButtonComponent = ({ discountScreen, actionId, refreshList, skuProduct }) => {
    const [deleteProduct, setDeleteProduct] = useState(false);
    
    const insertProductsScreen = () => navigateScreen(ROUTES_NAME.INSERT_PRODUCTS, actionId);

    const deleteItem = () => {setDeleteProduct(true)}

    return (
    <>
        <div className='action-product'>
            {
                !discountScreen ? <a onClick={insertProductsScreen}><IoMdCreate /></a> : null
            }
            <a onClick={deleteItem}><IoMdTrash/></a>
        </div>
        <DialogProductComponent discountScreen={discountScreen} active={deleteProduct} onActive={setDeleteProduct} refreshList={refreshList} idProduct={actionId} skuProduct={skuProduct}/>
    </>
    );
} 

export default ActionProductButtonComponent;