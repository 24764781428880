import React from 'react';
import { Modal } from 'react-bootstrap';
import './dialogProduct.css'
import productService from 'services/productService';
import { useTranslation } from 'react-i18next';
import { showToast } from 'utils/showToast';

const DialogProductComponent = ({ discountScreen, active, onActive, refreshList, idProduct, skuProduct }) => {
    const { t } = useTranslation();

    const deleteProduct = async () => {
        try{
            await productService.delete(idProduct);
            refreshList();
        }
        catch {
            showToast('error', t('toast-message.products.delete.error'));
        }
        finally {
            onActive(false);
        }
    }

    const cancelDelete = () => {onActive(false)}

    return (
        <Modal show={active}>
            <Modal.Body>
                {
                    !discountScreen 
                    ? <>
                        <h1 className='title-dialog'>{t("toast-message.products.delete.message.title") + skuProduct}?</h1>
                        <p className='description-dialog'>{t("toast-message.products.delete.message.description")}</p>
                    </>
                    : <>
                        <h1 className='title-dialog'>{t("toast-message.discount.delete.message.title") + skuProduct}?</h1>
                        <p className='description-dialog'>{t("toast-message.discount.delete.message.description")}</p>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className='cancel-button-dialog' type='button' onClick={() => cancelDelete()}>{t("buttons.cancel")}</button>
                <button className='delete-button-dialog' type='button' onClick={() => deleteProduct()}>{t("buttons.delete")}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default DialogProductComponent