import client from './client.js'
import ENDPOINTS from './endpoints.js'

const CategoryService = {
    get: async () => {
        const response = await client.get(ENDPOINTS.CATEGORY);
        const resultResponse =  response.data.data;

        let arrayCategories = [];

        await resultResponse.forEach(category => {
            arrayCategories.push({
                'id' : category.id,
                'name' : category.name,
            });
        });
        
        return arrayCategories; 
    },

    post: async (nameCategory) => {
        if (nameCategory != '') {
            const categories = await client.get(ENDPOINTS.CATEGORY);
            const categoriesArray = Object.values(categories.data.data);
            
            // Checks if the category already exists by name
            const existingCategory = categoriesArray.find(category => category.name.toLowerCase() === nameCategory.trim());


            if (existingCategory) {
                // If the category already exists, returns its ID
                return existingCategory.id;
            } else {
                // If the category does not exist, create a new one
                const newCategoryResponse = await client.post(ENDPOINTS.CATEGORY_POST, { 'name' : nameCategory.trim() });
                return newCategoryResponse.data.id;
            }
        } else {
            return '';
        }
    }
}

export default CategoryService