import React, { useState } from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import './styles/search.css';
import { useTranslation } from 'react-i18next';
import { showToast } from 'utils/showToast';

const SearchComponent = ({ screen, arrayToFilter, onArrayFiltered }) => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState('');

    let arrayFiltered = [];
    const search = searchValue.split(': ');
    let keyValue;

    const filtreOrders = async () => {
        try {
            if (search[0] == 'client' || search[0] == 'cliente') {
                keyValue = 'name';
            } else if (search[0] == 'date' || search[0] == 'data') {
                keyValue = 'date';
            } else if (search[0] == 'value' || search[0] == 'valor') {
                keyValue = 'totalValue';
            } else if (search[0] == 'cnpj') {
                keyValue = 'cnpj';
            } else {
                keyValue = 'name';
                search[1] = search[0];
            }

            const filteredOrders = await arrayToFilter.filter((order) =>
                order[keyValue].toLowerCase().includes(search[1].toLowerCase())
            );
            
            await filteredOrders.map((order) => {
                arrayFiltered.push({
                    'id': order.id,
                    'name': order.name,
                    'cnpj': order.cnpj,
                    'date' : order.date, 
                    'status' : order.status,
                    'statusStyle' : order.statusStyle,
                    'totalValue' : order.totalValue
                });
            });

            onArrayFiltered(arrayFiltered);
        }
        catch {
            showToast('error', t('toast-message.orders.filtre.error'));
        }
    }

    const filtreProducts = async () => {
        try {
            if (search[0] == 'name' || search[0] == 'nome') {
                keyValue = 'name';
            } else if (search[0] == 'weight' || search[0] == 'peso') {
                keyValue = 'weight';
            } else {
                keyValue = 'name';
                search[1] = search[0];
            }
    
            const filteredProducts = await arrayToFilter.filter((product) =>
                product[keyValue].toLowerCase().includes(search[1].toLowerCase())
            );
            
            await filteredProducts.map((product) => {
                arrayFiltered.push({
                    'id' : product.id,
                    'imageUrl' : product.imageUrl,
                    'name' : product.name,
                    'weight' : product.weight,
                    'boxQuantity' : product.boxQuantity,
                    'variableWeight' : product.variableWeight,
                    'prices' : product.prices,
                    'shelfTypes' : product.shelfTypes,
                    'lifeTimeInDays' : product.lifeTimeInDays,
                });
            });

            onArrayFiltered(arrayFiltered);
        }
        catch {
            showToast('error', t('toast-message.orders.filtre.error'));
        }
    }

    const searchFilter = async () => {
        try {
            switch (screen) {
                case 'orders':
                    return  filtreOrders();
                case 'products':
                    return  filtreProducts();
            }
        }
        catch {
            showToast('error', t('toast-message.products.filtre.error'));
        }
        finally {
            setSearchValue('');
        }
    }

    return (
        <div className='search'>
            <button onClick={() => searchFilter()}><i><BiSearchAlt /></i></button>
            <input 
                className='input-login'
                placeholder={t('component.search')}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
            />
        </div>
    )
}

export default SearchComponent