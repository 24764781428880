import './productsScreen.css';
import React, { useState, useEffect } from 'react';
import SearchComponent from 'components/searchComponent.js';
import NavHomeComponent from 'components/navHomeComponent.js';
import TableProductsScreen from './products/tableProductsScreen.js';
import CardProductsScreen from './products/cardProductsScreen.js';
import { useTranslation } from 'react-i18next';
import productService from 'services/productService.js';
import categoryService from 'services/categoryService.js';
import LoadingComponent from 'components/loadingComponent';
import { ToastComponent, showToast } from 'utils/showToast';
import ButtonFilterComponent from 'components/buttonFilterComponent';
import { PiCardsLight, PiTableLight } from 'react-icons/pi';
import { TfiReload } from 'react-icons/tfi';
import downloadExcel from 'utils/downloadExcel.js';

let page = 1;

const ProductsScreen = () => {
    const { t } = useTranslation();
    
    const [cardProduct, setCardProduct] = useState(() => {
        const storedValue = localStorage.getItem('typeTable');
        return storedValue ? JSON.parse(storedValue) : false;
    });
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [pageProducts, setPageProducts] = useState(1);
    const [haveProductsYet, setHaveProductsYet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    // Save value to localStorage
    const saveToLocalStorage = () => {
        localStorage.setItem('typeTable', JSON.stringify(cardProduct));
    };

    const getAllProducts = async () => {
        setIsLoading(true);

        try {
            const response = await productService.get(pageProducts);
            
            if (page != 1) {
                setProducts((products) => [...products, ...response]);
            } else {
                setProducts(response);
            }

            await checkHaveInNextPage(pageProducts);
        } 
        catch {
            showToast('error', t('toast-message.products.get.error'));
        } 
        finally {
            setIsLoading(false);
        }
    };

    const getAllCategories = async () => {
        setIsLoading(true);

        try {
            const response = await categoryService.get();
            setCategories(response);
        }
        catch {
            showToast('error', t('toast-message.products-categories.get.error'));
        }
        finally {
            setIsLoading(false);
        }
    };

    const checkHaveInNextPage = async (pageProduct) => {
        const pageNumber = pageProduct + 1;

        const response = await productService.get(pageNumber);

        if (response.length > 0) {
            await setHaveProductsYet(true);
        } else {
            await setHaveProductsYet(false);
        }
    };

    useEffect(() => {
        window.addEventListener('beforeunload', saveToLocalStorage);
        
        getAllProducts();
        getAllCategories();
        
        // Remove event listener to prevent memory leaks
        return () => {
            window.removeEventListener('beforeunload', saveToLocalStorage);
        };
    }, [pageProducts]);

    const generateExcel = () => {
        let arrayProductsFormatted = [];
    
        products.forEach(product => {
            arrayProductsFormatted.push([
                product.sku,
                product.name,
                product.category,
                product.boxQuantity,
                product.prices[0].price,
                product.shelfTypes[0],
            ]);
        });
    
        try {
            downloadExcel(t('nav-home.products'), ['sku', 'name', 'category', 'boxQuantity', 'price', 'shelfTypes'], arrayProductsFormatted);
        }
        catch {
            showToast('error', t('toast-message.products.download.error'));
        }
    };

    const isCardProduct = () => {
        setCardProduct(prevCardProduct => !prevCardProduct);
    };

    const refresh = () => window.location.reload();

    return (
        <NavHomeComponent 
            screen={
                <>
                    <ToastComponent />
                    <SearchComponent screen='products' arrayToFilter={products} onArrayFiltered={setProducts}/>
                    {
                        isLoading
                        ? <LoadingComponent />
                        : <div className='actions-category'>
                            <div className='w-100'>
                                <ul className='shelflife-list'>
                                    <h1 className='title-filter'>Shelf Life</h1>
                                    <li key={'productGreen'}><ButtonFilterComponent type={'shelfType'} filter={'GREEN'} isFilter={isFilter} onIsFilter={setIsFilter} arrayToFilter={products} onSetArrayFiltered={setProducts} onSetLoading={setIsLoading} refresh={() => refresh()} styles={'validate-filter validate-green-filter'} /></li>
                                    <li key={'productYellow'}><ButtonFilterComponent type={'shelfType'} filter={'YELLOW'} isFilter={isFilter} onIsFilter={setIsFilter} arrayToFilter={products} onSetArrayFiltered={setProducts} onSetLoading={setIsLoading} refresh={() => refresh()} styles={'validate-filter validate-yellow-filter'} /></li>
                                    <li key={'productOrange'}><ButtonFilterComponent type={'shelfType'} filter={'ORANGE'} isFilter={isFilter} onIsFilter={setIsFilter} arrayToFilter={products} onSetArrayFiltered={setProducts} onSetLoading={setIsLoading} refresh={() => refresh()} styles={'validate-filter validate-orange-filter'} /></li>
                                    <li key={'productRed'}><ButtonFilterComponent type={'shelfType'} filter={'RED'} isFilter={isFilter} onIsFilter={setIsFilter} arrayToFilter={products} onSetArrayFiltered={setProducts} onSetLoading={setIsLoading} refresh={() => refresh()} styles={'validate-filter validate-red-filter'} /></li>                                
                                </ul>
                            </div>
                            {
                                categories.length > 0
                                ? <div className='w-100'>
                                    <ul className='categories-list'>
                                        <h1 className='title-filter'>{t('products-screen.title.categories')}</h1>
                                    {
                                        categories.map((category) => (
                                            <li key={category.id}><ButtonFilterComponent title={category.name} type={'category'} filter={category.name} isFilter={isFilter} onIsFilter={setIsFilter} arrayToFilter={products} onSetArrayFiltered={setProducts} onSetLoading={setIsLoading} refresh={() => refresh()} styles={'button-category'}/></li>
                                        ))
                                    }
                                    </ul>
                                </div>
                                : null
                            }
                        </div>
                    } 
                    <div className='actions-table'>
                        <button disabled={isLoading} className='button-export' onClick={() => generateExcel()}>{t('buttons.export-product')}</button>
                        <button className='switch-show' onClick={isCardProduct}>
                            { 
                                cardProduct 
                                ? <PiTableLight />
                                : <PiCardsLight /> 
                            }
                        </button>
                        <button disabled={isLoading} className='button-filter' onClick={() => refresh()}><TfiReload /></button>
                    </div>
                    { 
                        cardProduct 
                        ? <CardProductsScreen products={products} onSetProducts={setProducts} isLoading={isLoading} refresh={() => refresh()}/> 
                        : <TableProductsScreen products={products} onSetProducts={setProducts} isLoading={isLoading} refresh={() => refresh()}/> 
                    }
                    {
                        haveProductsYet
                        ? <div className='div-see-more'>
                            <button disabled={isLoading} className='button-more' onClick={() => setPageProducts(page++)}>{t('buttons.see-more')}</button>
                        </div>
                        : null
                    }
                </>
            }/>
    )
}

export default ProductsScreen
