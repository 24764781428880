import axios from 'axios';
import ROUTES_NAME from 'routes/routeName';
import navigateScreen from 'utils/navigateScreen';
import ENDPOINTS from './endpoints';

const token = JSON.parse(localStorage.getItem('accessToken'));

const url = window.location.href;
const urlDashProd = 'https://www.dashboard.sellout-hub.com';

const apiProd = 'https://api.sellout-hub.com/v1';
const apiDev = 'https://sellouthub-api-dev-d56453b7356b.herokuapp.com/v1';

const baseURL = url.includes(urlDashProd) ? apiProd : apiDev;

const client = axios.create({
    baseURL: baseURL,
    headers: {
        Authorization: `Bearer ${token}`
    },
    // withCredentials: true,
});

client.interceptors.response.use( 
    (response) => response, 
    async (error) => {
        const status = error.response ? error.response.status : null;

        if (status === 401) {
            let refreshTokenExpired = await String(localStorage.getItem('refreshToken'));

            if (refreshTokenExpired != '') {
                try {
                    let expiredToken = await { refreshToken : refreshTokenExpired.replace(/[""]/g, '') };

                    const response = await client.post(ENDPOINTS.REFRESH_TOKEN, expiredToken);
                    const { accessToken, refreshToken } = response.data;
                
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');

                    await localStorage.setItem('accessToken', JSON.stringify(accessToken));
                    await localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
                    
                    window.location.reload();
                } 
                catch (refreshError) {
                    handleTokenRefreshFailure();
                    return Promise.reject(refreshError);
                }
            } else {
                handleTokenRefreshFailure();
                return Promise.reject(error);
            }
        }
        
        return Promise.reject(error);
    }
);

const handleTokenRefreshFailure = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    setTimeout(() => {
        navigateScreen(ROUTES_NAME.LOGIN);
    }, 2500);
}

export default client