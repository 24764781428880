import client from './client.js'
import ENDPOINTS from './endpoints.js'
import { formatDate } from 'utils/formatDate.js';
import { formatMoney } from 'utils/formatMoney.js';
import formatCnpj from 'utils/formatCnpj.js';

const OrderService = {
    getByAccounIdAndStatus: async (accountId, status) => {
        const response = await client.get(`${ENDPOINTS.ORDER_ACCOUNT_ID + accountId}&status=${status.toUpperCase()}`);
        const resultResponse =  response.data.data;

        let arrayOrders = [];

        await resultResponse.forEach(order => {
            let productPrice, productQty, variableWeight, productBoxQty, productWeight,clientCnpjUnformatted, statusStyle, totalValueUnformatted;

            order.orderProducts.forEach(product => {
                productPrice = (product.productPrice / 100);
                productQty = product.productQty;
                variableWeight = product.variableWeight;
                productBoxQty = product.boxQuantity;
                productWeight = product.weight;
            });

            order.account.documents.forEach(document => {
                clientCnpjUnformatted = document.document;
            });

            if (variableWeight) {
                totalValueUnformatted = productPrice * productWeight * productBoxQty * productQty;  
            } else {
                totalValueUnformatted = productPrice * productQty *  productBoxQty;
            }

            const totalValue = formatMoney(totalValueUnformatted);
            const clientCNPJ = formatCnpj(clientCnpjUnformatted);
            const orderDate = formatDate(order.createdAt);
            
            arrayOrders.push({
                'id': order.id,
                'accountId': order.accountId,
                'name' : order.account.company_name,
                'cnpj' : clientCNPJ,
                'date' : orderDate, 
                'status' : order.status,
                'statusStyle' : statusStyle,
                'totalValue' : totalValue
            });
        });
        
        return arrayOrders; 
    },

    getByAccountId: async (accountId) => {
        const response = await client.get(ENDPOINTS.ORDER_ACCOUNT_ID + accountId);
        const resultResponse =  response.data.data;

        let arrayOrders = [];

        await resultResponse.forEach(order => {
            let productPrice, productQty, variableWeight, productBoxQty, productWeight,clientCnpjUnformatted, statusStyle, totalValueUnformatted;

            order.orderProducts.forEach(product => {
                productPrice = (product.productPrice / 100);
                productQty = product.productQty;
                variableWeight = product.variableWeight;
                productBoxQty = product.boxQuantity;
                productWeight = product.weight;
            });

            order.account.documents.forEach(document => {
                clientCnpjUnformatted = document.document;
            });

            if (variableWeight) {
                totalValueUnformatted = productPrice * productWeight * productBoxQty * productQty;  
            } else {
                totalValueUnformatted = productPrice * productQty *  productBoxQty;
            }

            const totalValue = formatMoney(totalValueUnformatted);
            const clientCNPJ = formatCnpj(clientCnpjUnformatted);
            const orderDate = formatDate(order.createdAt);
            
            arrayOrders.push({
                'id': order.id,
                'accountId': order.accountId,
                'name' : order.account.company_name,
                'cnpj' : clientCNPJ,
                'date' : orderDate, 
                'status' : order.status,
                'statusStyle' : statusStyle,
                'totalValue' : totalValue
            });
        });

        return arrayOrders; 
    },

    getByStatus: async (status) => {
        const response = await client.get(ENDPOINTS.ORDER_STATUS + status.toUpperCase());
        const resultResponse =  response.data.data;

        let arrayOrders = [];

        await resultResponse.forEach(order => {
            let productPrice, productQty, variableWeight, productBoxQty, productWeight, clientCnpjUnformatted, statusStyle, totalValueUnformatted;

            order.orderProducts.forEach(product => {
                productPrice = (product.productPrice / 100);
                productQty = product.productQty;
                variableWeight = product.variableWeight;
                productBoxQty = product.boxQuantity;
                productWeight = product.weight;
            });

            order.account.documents.forEach(document => {
                clientCnpjUnformatted = document.document;
            });

            if (variableWeight) {
                totalValueUnformatted = productPrice * productWeight * productBoxQty * productQty;  
            } else {
                totalValueUnformatted = productPrice * productQty *  productBoxQty;
            }

            const totalValue = formatMoney(totalValueUnformatted);
            const clientCNPJ = formatCnpj(clientCnpjUnformatted);
            const orderDate = formatDate(order.createdAt);
            
            arrayOrders.push({
                'id': order.id,
                'accountId': order.accountId,
                'name' : order.account.company_name,
                'cnpj' : clientCNPJ,
                'date' : orderDate, 
                'status' : order.status,
                'statusStyle' : statusStyle,
                'totalValue' : totalValue
            });
        });
        
        return arrayOrders; 
    },

    get: async (page) => {
        const response = await client.get(`${ENDPOINTS.ORDER}?page=${page}&size=40`);
        const resultResponse =  response.data.data;

        let arrayOrders = [];

        await resultResponse.forEach(order => {
            let productPrice, productQty, variableWeight, productBoxQty, productWeight, clientCnpjUnformatted, totalValueUnformatted;

            order.orderProducts.forEach(product => {
                productPrice = (product.productPrice / 100);
                productQty = product.productQty;
                variableWeight = product.variableWeight;
                productBoxQty = product.boxQuantity;
                productWeight = product.weight;
            });

            order.account.documents.forEach(document => {
                clientCnpjUnformatted = document.document;
            });

            if (variableWeight) {
                totalValueUnformatted = productPrice * productWeight * productBoxQty * productQty;  
            } else {
                totalValueUnformatted = productPrice * productQty *  productBoxQty;
            }

            const totalValue = formatMoney(totalValueUnformatted);
            const clientCNPJ = formatCnpj(clientCnpjUnformatted);
            const orderDate = formatDate(order.createdAt);
            
            arrayOrders.push({
                'id': order.id,
                'accountId': order.accountId,
                'name' : order.account.company_name,
                'cnpj' : clientCNPJ,
                'date' : orderDate, 
                'status' : order.status,
                'totalValue' : totalValue
            });
        });
        
        return arrayOrders; 
    },

    getReport: async () => {
        const response = await client.get(ENDPOINTS.ORDER_REPORT);
        const resultResponse = response.data;
        
        return resultResponse;
    },

    patch: async (validationsUpdate) => {
        await client.patch(ENDPOINTS.ORDER_ID + validationsUpdate);
    }
}

export default OrderService