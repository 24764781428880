import React, { useState } from 'react';
import './forgotPasswordScreen.css';
import SuccessImg from 'assets/images/success-image.jpg';
import NavLoginComponent from 'components/navLoginComponent.js';
import CheckPasswordComponent from './components/checkPasswordComponent.js';
import NotFoundComponent from 'routes/screens/notFoundScreen.js';
import navigateScreen from 'utils/navigateScreen';
import ROUTES_NAME from 'routes/routeName';
import { useTranslation } from 'react-i18next';

const ForgotPasswordScreen = () => {
    const { t } = useTranslation();
  
    const [stepCounter, setStepCounter] = useState(0);

    const backToLogin = () => navigateScreen(ROUTES_NAME.LOGIN);

    const whichStep = () => setStepCounter(stepCounter + 1);
    
    let typeEmail = 
    <>
      <p className='description mb-3'>{t('login.forgot-password.type-email')}</p>
      <input
          className='input-login mb-5'
          type='text' 
          name='email'
          // value={this.state.newComment.name}
          // onChange={this.typeInput}          
          placeholder='E-mail' 
          required />
      
      <button className='submit-button mb-3' type='button' onClick={whichStep}>{t('login.forgot-password.send')}</button>
      <button className='back-button' type='button' onClick={backToLogin}>{t('login.forgot-password.back')}</button>
    </>;

    let typeCode =
    <>
      <p className='description mb-3'>{t('login.forgot-password.type-code')}</p>
      <input
        className='input-login mb-3'
        type='text' 
        name='cnpj'
        // value={this.state.newComment.name}
        // onChange={this.typeInput}          
        required />
      <a className='forgot-password color-primary mb-5'>{t('login.forgot-password.resend-code')}</a>
      <button className='submit-button' type='button' onClick={whichStep}>{t('login.forgot-password.continue')}</button>
    </>;

    let newPassword = 
    <>
      <p className='description mb-3'>{t('login.forgot-password.type-new-password')}</p>
      <input
          className='input-login mb-4'
          type='text' 
          name='newPassword'
          // value={this.state.newComment.name}
          // onChange={this.typeInput}          
          placeholder={t('login.forgot-password.new-password')} 
          required />
        <CheckPasswordComponent title={t('login.forgot-password.validate-number')} />
        <CheckPasswordComponent title={t('login.forgot-password.validate-upper-case')} />
        <CheckPasswordComponent title={t('login.forgot-password.validate-lower-case')} />
        <CheckPasswordComponent title={t('login.forgot-password.validate-special-character')} />
      <button className='submit-button mt-4' type='button' onClick={whichStep}>{t('login.forgot-password.continue')}</button>
    </>;

    let success =
    <>
      <div className='success-div'>
        <img width={250} src={SuccessImg} alt='success-image' />
        <p className='description mt-5 mb-5'>{t('login.forgot-password.message-success')}</p>
        <button className='submit-button' type='button' onClick={backToLogin}>{t('login.forgot-password.conclude')}</button>
    </div>
    </>;

    switch (stepCounter) {
      case 0:
        return <NavLoginComponent screen={typeEmail} />;
      case 1:
        return <NavLoginComponent screen={typeCode} />;
      case 2:
        return <NavLoginComponent screen={newPassword} />;
      case 3:
        return <NavLoginComponent screen={success} />;
      default:
        return <NotFoundComponent />;
    }
}

export default ForgotPasswordScreen