import './cardProductsScreen.css';
import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import ToggleButtonComponent from '../components/toggleButtonComponent';
import navigateScreen from 'utils/navigateScreen';
import ROUTES_NAME from 'routes/routeName';
import { useTranslation } from 'react-i18next';
import LoadingComponent from 'components/loadingComponent';
import { formatMoney, unformatMoney } from 'utils/formatMoney';
import DialogProductComponent from '../components/dialogProductComponent';
import DefaultImage from 'assets/images/white-image.jpg';

const CardProductsScreen = ({ products, onSetProducts, isLoading, refresh }) => {
    const { t } = useTranslation();

    const [deleteProduct, setDeleteProduct] = useState(false);
    const [idProduct, setIdProduct] = useState('');
    const [skuProduct, setSkuProduct] = useState('');

    const minMaxPrices = (product) => {
        const prices = product.prices.map(item => parseFloat(unformatMoney(item.price))); 

        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);

        return { minPrice, maxPrice };
    };

    const deleteItem = (idProduct, skuProduct) => {setDeleteProduct(true); setIdProduct(idProduct); setSkuProduct(skuProduct)}

    const insertProductsById = (id) => navigateScreen(ROUTES_NAME.INSERT_PRODUCTS, id);
    const insertProduct = () => {navigateScreen(ROUTES_NAME.INSERT_PRODUCTS)};

    return (
        isLoading
        ?   <LoadingComponent />
        :   products.length == 0
            ? <a onClick={insertProduct} className='title-warning'>{t('products-screen.products.empty')}</a>
            : <section className='mainCard'>
                {
                    products.map((product) => (
                    <>
                        <div className='ComponentCard'>
                            <div className='d-flex justify-content-end'> 
                                <button onClick={() => deleteItem(product.id, product.sku)} className='button-remove'><FaTrash /></button> 
                            </div>
                            <div className='d-flex'>
                                <ul className='validates'>
                                    { product.shelfTypes.includes('GREEN') ? <li key={'cardGreen'} className='validate-green'></li> : null}
                                    { product.shelfTypes.includes('YELLOW') ? <li key={'cardYellow'} className='validate-yellow'></li> : null}
                                    { product.shelfTypes.includes('ORANGE') ? <li key={'cardOrange'} className='validate-orange'></li> : null}
                                    { product.shelfTypes.includes('RED') ? <li key={'cardRed'} className='validate-red'></li> : null}
                                </ul>
                            </div>
                            <img className='image-card' src={product.imageUrl != undefined ? product.imageUrl : DefaultImage} alt='Imagem do produto' />
                            <div className='info-product'>
                                <h2>{product.sku}</h2>
                                <h1>{product.name}</h1>
                                <div className='toogle-button'> 
                                    <ToggleButtonComponent nameRef={'active'} toggleValue={product} setToggleValues={onSetProducts} refreshList={() => refresh()} />
                                    <p>
                                    {
                                        product.prices.length == 0
                                        ? ''
                                        : `${formatMoney(minMaxPrices(product).minPrice)} - ${formatMoney(minMaxPrices(product).maxPrice)}`
                                    }
                                    </p>
                                </div>
                                <div className='action-info'>
                                    <div>
                                        <h3><span>{t('products-table.validate')}:</span> {product.prices.length != 0 ? product.lifeTimeInDays : 0} <span>{t('products-table.days')}</span></h3>
                                        <div className='box-info'>
                                            <p>{product.weight} kg</p>
                                            <p>|</p>
                                            <p>{product.boxQuantity} un</p>
                                        </div>
                                    </div>
                                    <button className='button-action' onClick={() => insertProductsById(product.id)}>{t('products-table.edit')}</button>
                                </div>
                            </div>
                        </div>
                        <DialogProductComponent active={deleteProduct} onActive={setDeleteProduct} refreshList={refresh} idProduct={idProduct} skuProduct={skuProduct}/>
                    </>
                    ))
                }
            </section>
    )
}

export default CardProductsScreen
