import React, { useEffect, useState } from 'react';
import './styles/dropImageInput.css'
import { useTranslation } from 'react-i18next';
import LoadingComponent from './loadingComponent';
import { showToast } from 'utils/showToast';

const DropImageInput = ({ formData, setFormData }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(null);
  const [image, setImage] = useState('');

  const handleDrop = (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const file = e.dataTransfer.files[0];

      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
        setFormData({ ...formData, image: file});
      }
    }
    catch (error) {
      showToast('error', t('toast-message.insert-products.get-image.error'))
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();    
  };

  const putImage = async (image) => {
    if (image != '') {
      await setImage(image);
    }
  }

  useEffect(() => {
    setIsLoading(true);

    try {
      putImage(formData.image);
    }
    catch (error) {
      showToast('error', t('toast-message.insert-products.get-image.error'))
    }
    finally {
      setIsLoading(false);
    }
  }, [formData.image]);

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className='drop-image'
    >
      {
        isLoading
        ? <LoadingComponent /> 
        : 
          image 
          ? <img
              src={image}
              alt='Dropped'
              className='content-image'
            />
          : <p>{t('drop-image.message')}</p>
      }
    </div>
  );
};

export default DropImageInput;