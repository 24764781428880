import client from './client.js'
import ENDPOINTS from './endpoints.js'
import { formatMoney } from 'utils/formatMoney.js';
import categoryService from './categoryService.js';
import priceService from './priceService.js';
import { dateFormartISO } from 'utils/formatDate.js';

const ProductService = {
    getById: async (id) => {
        const response = await client.get(`${ENDPOINTS.PRODUCT}/${id}`);
        const resultResponse = response.data;

        let productById = [];
        let arrayProductPrices = [];
        let isActive = false;

        await resultResponse.productPrices.map(productPrice => {
            arrayProductPrices.push({
                'id' : productPrice.id,
                'isActive' : productPrice.active,
                'price' : productPrice.price,
                'expirationDatetime' : productPrice.expirationDatetime,
                'manufacturingDatetime' : productPrice.manufacturingDatetime,
                'minimalOrder' : productPrice.minimalOrder,
                'lifeTimeInDays' : productPrice.lifeTimeInDays,
            });

            isActive = productPrice.active;
        });
        
        await productById.push({
            'id' : resultResponse.id,
            'image' : resultResponse.image_url,
            'name' : resultResponse.name,
            'category' : resultResponse.productCategory.name,
            'sku' : resultResponse.sku,
            'grammage' : resultResponse.grammage,
            'weight' : resultResponse.weight,
            'boxQuantity' : resultResponse.boxQuantity,
            'variableWeight' : resultResponse.variableWeight,
            'published': isActive,
            'prices' : arrayProductPrices
        });
        
        return productById; 
    },

    get: async (page) => {
        const response = await client.get(`${ENDPOINTS.PRODUCT}?page=${page}&size=40`);
        const resultResponse = response.data.data;

        let arrayProducts = [];

        await resultResponse.forEach(async (product) => {
            let arrayProductPrices = [];
            let arrayShelfTypes = [];
            let lifeTimeInDays, isActive;

            product.productPrices.forEach(productPrice => {
                arrayProductPrices.push({
                    'id' : productPrice.id,
                    'price' : formatMoney(productPrice.price / 100)
                });
                lifeTimeInDays = productPrice.lifeTimeInDays;
                arrayShelfTypes.push(productPrice.shelfType);
                isActive = productPrice.active;
            });
            
            arrayProducts.push({
                'id' : product.id,
                'imageUrl' : product.image_url,
                'sku' : product.sku,
                'name' : product.name,
                'category' : product.productCategory.name,
                'weight' : product.weight,
                'boxQuantity' : product.boxQuantity,
                'variableWeight' : product.variableWeight,
                'prices' : arrayProductPrices,
                'active': isActive,
                'shelfTypes' : arrayShelfTypes,
                'lifeTimeInDays' : lifeTimeInDays,
            });
        });

        return arrayProducts;
    },

    post: async (newProduct) => {
        const formData = new FormData();

        const categoryId = await categoryService.post(newProduct.category.toLowerCase());

        newProduct.productCategoryId = newProduct.category;
        delete newProduct.category;
        newProduct.productCategoryId = categoryId;
        
        const prices = newProduct.prices;
        delete newProduct.prices;

        const published = newProduct.published;
        delete newProduct.published;

        formData.append('image', newProduct.image);
        formData.append('name', newProduct.name);
        formData.append('weight', newProduct.weight);
        formData.append('boxQuantity', newProduct.boxQuantity);
        formData.append('productCategoryId', newProduct.productCategoryId);
        formData.append('variableWeight', newProduct.variableWeight);
        formData.append('sku', newProduct.sku);
        formData.append('grammage', newProduct.grammage);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const response = await client.post(ENDPOINTS.PRODUCT, formData, config);
        const productId = response.data.id; 

        await priceService.post(productId, prices, published);
    },

    postBatch: async (arrayProductsUnformatted) => {
        let arrayJsonProductsFormatted = [];

        for (const product of arrayProductsUnformatted) {
            // [0] = sku
            // [1] = category
            // [2] = name
            // [3] = weight
            // [4] = boxQuantity
            // [5] = variableWeight

            // [6] = published
            // [7] = prices
            // [8] = manufacturingDatetime
            // [9] = expirationDatetime
            // [10] = minimalOrder

            let categoryId = await categoryService.post(product[1]);

            let price = parseInt(String(product[7]).replace(/[^\d]/g, ''));
            let minimalOrder = parseInt(String(product[10]).replace(/[^\d]/g, ''));

            let manufacturingDate = dateFormartISO(product[8].trim());
            let expirationDate = dateFormartISO(product[9].trim());

            arrayJsonProductsFormatted.push({
                sku : String(product[0]),
                productCategoryId : categoryId,
                name : product[2],
                weight : parseInt(product[3]),
                boxQuantity : product[4],
                prices: [{
                    active : Boolean(product[6]),
                    price: price,
                    manufacturingDatetime: manufacturingDate,
                    expirationDatetime: expirationDate,
                    minimalOrder: minimalOrder,
                    batchCode: '1234567'
                }],
                variableWeight : Boolean(product[5]),
                grammage : 200
            });
        };

        const arrayProducts = arrayJsonProductsFormatted.reduce((acc, obj) => {
            const skuExistIndex = acc.findIndex(item => item.sku === obj.sku);
        
            if (skuExistIndex !== -1) {
                // if the SKU exists then put the prices on it
                acc[skuExistIndex].prices.push(...obj.prices);
            } else {
                // If the SKU does not exist, add it to the array
                acc.push(obj);
            }
        
            return acc;
        }, []);

        await client.post(ENDPOINTS.PRODUCT_BATCH, arrayProducts);
    },

    patch: async (updatedProduct) => {
        const formData = new FormData();

        const categoryId = await categoryService.post(updatedProduct.category.toLowerCase());

        updatedProduct.productCategoryId = updatedProduct.category;
        delete updatedProduct.category;
        updatedProduct.productCategoryId = categoryId;

        // Filtrar objetos com a propriedade 'id' e criar uma nova lista
        const pricesWithId = updatedProduct.prices.filter(item => item.id != null);

        // Filtrar objetos sem a propriedade 'id' e criar uma nova lista
        const pricesWithoutId = updatedProduct.prices.filter(item => item.id == null);

        const pricesDelete = updatedProduct.pricesDelete;
        delete updatedProduct.pricesDelete;

        const published = updatedProduct.published;
        delete updatedProduct.published;

        const productId = updatedProduct.id;
        delete updatedProduct.id;

        if (updatedProduct.image instanceof File) {
            formData.append('image', updatedProduct.image);
        } else {
            delete updatedProduct.image;
        }

        formData.append('name', updatedProduct.name);
        formData.append('weight', updatedProduct.weight);
        formData.append('boxQuantity', updatedProduct.boxQuantity);
        formData.append('productCategoryId', updatedProduct.productCategoryId);
        formData.append('variableWeight', updatedProduct.variableWeight);
        formData.append('sku', updatedProduct.sku);
        formData.append('grammage', updatedProduct.grammage);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        await client.patch(`${ENDPOINTS.PRODUCT_ID}${productId}`, formData, config);
        
        if (pricesDelete.length > 0) {
            await priceService.delete(pricesDelete);
        }

        if (pricesWithoutId.length > 0) {
            await priceService.post(productId, pricesWithoutId, published);
        }

        if (pricesWithId.length > 0) {
            const arrayIdProduct = pricesWithId.map((price) => price.id)
            await priceService.patch(arrayIdProduct, published);
        }
    },

    delete: async (id) => {
        await client.delete(ENDPOINTS.PRODUCT_ID + id);
    }
}

export default ProductService