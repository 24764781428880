import React, { useState } from 'react';
import './styles/statusOrderButton.css'
import { BsBagCheck, BsBagX } from 'react-icons/bs';
import orderService from 'services/orderService';
import { showToast } from 'utils/showToast';
import navigateScreen from 'utils/navigateScreen';
import ROUTES_NAME from 'routes/routeName';

const APPROVED_ORDER = 'FINISHED';
const REFUSED_ORDER = 'CANCELLED';

const StatusOrderButton = ({ orderId }) => {
    const [isLoading, setIsLoading] = useState(false);
    
    const changeStatus = async (choiceStatus) => {
        setIsLoading(true);
        let status;
        
        if (choiceStatus == 'approved') {
            status = APPROVED_ORDER;
        } else if (choiceStatus == 'refused') {
            status = REFUSED_ORDER;
        }

        try {
            const validationsUpdate = await `${orderId}/${status}`
            
            await orderService.patch(validationsUpdate);
            navigateScreen(ROUTES_NAME.ORDERS)
        }
        catch {
            showToast('error', t('toast-message.orders.updateStatus.error'));
            console.log('error');
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div className='dropleft-order'>
            <button disabled={isLoading} className='color-approved' onClick={() => changeStatus('approved')}><BsBagCheck  className='color-approved'/></button>
            <button disabled={isLoading} className='color-refused' onClick={() => changeStatus('refused')}><BsBagX  className='color-refused'/></button>
        </div>
    )
}

export default StatusOrderButton