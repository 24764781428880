import React, { useState } from 'react';
import { showToast } from 'utils/showToast';
import './styles/buttonFilter.css'
import { AiOutlineClose } from 'react-icons/ai';
import orderService from 'services/orderService';
import { useTranslation } from 'react-i18next';

const ButtonFilterComponent = ({ type, accountId, title, filter, arrayToFilter, onSetArrayFiltered, onSetLoading, isFilter, onIsFilter, refresh, styles}) => {
    const { t } = useTranslation();

    const [isActive, setIsActive] = useState(false);
    
    let arrayFiltered = [];
    
    const filterShelfType = (filter) => {
        onSetLoading(true);
        try {
            setIsActive(true);
            onIsFilter(true);
            const fitlteredShelfTypes = arrayToFilter.filter(product => product.shelfTypes.includes(filter));
            
            fitlteredShelfTypes.map((product) => {
                arrayFiltered.push({
                    'id' : product.id,
                    'imageUrl' : product.imageUrl,
                    'name' : product.name,
                    'category' : product.category,
                    'weight' : product.weight,
                    'boxQuantity' : product.boxQuantity,
                    'variableWeight' : product.variableWeight,
                    'prices' : product.prices,
                    'shelfTypes' : product.shelfTypes,
                    'lifeTimeInDays' : product.lifeTimeInDays,
                });
            });

            onSetArrayFiltered(arrayFiltered);
        }
        catch {
            showToast('error', t('toast-message.products.filtre.error'));
        }
        finally {
            onSetLoading(false);
        }
    }

    const filterCategory = (filter) => {
        onSetLoading(true);

        try {
            setIsActive(true);
            onIsFilter(true);
            const fitlteredCategories = arrayToFilter.filter(product => product.category.includes(filter));
            
            fitlteredCategories.map((product) => {
                arrayFiltered.push({
                    'id' : product.id,
                    'imageUrl' : product.imageUrl,
                    'name' : product.name,
                    'category' : product.category,
                    'weight' : product.weight,
                    'boxQuantity' : product.boxQuantity,
                    'variableWeight' : product.variableWeight,
                    'prices' : product.prices,
                    'shelfTypes' : product.shelfTypes,
                    'lifeTimeInDays' : product.lifeTimeInDays,
                });
            });

            onSetArrayFiltered(arrayFiltered);
        }
        catch {
            showToast('error', t('toast-message.products.filtre.error'));
        }
        finally {
            onSetLoading(false);
        }
    }

    const filterStatus = async (filter) => {
        onSetLoading(true);

        try {
            setIsActive(true);
            onIsFilter(true);

            if (accountId != '') {
                const ordersFiltered = await orderService.getByAccounIdAndStatus(accountId, filter);
                ordersFiltered.shift();
        
                ordersFiltered.map((order) => {
                    if (order.status == 'FINISHED') {
                        order.status = t('orders-table.status.approved');
                        order.statusStyle = 'approved';
                    } else if (order.status == 'CANCELLED') {
                        order.status = t('orders-table.status.refused');
                        order.statusStyle = 'refused';
                    } else {
                        order.status = t('orders-table.status.pending');
                        order.statusStyle = 'pending';
                    }
                })
                
                onSetArrayFiltered(ordersFiltered);
            } else {
                const ordersFiltered = await orderService.getByStatus(filter);
                ordersFiltered.shift();
        
                ordersFiltered.map((order) => {
                    if (order.status == 'FINISHED') {
                        order.status = t('orders-table.status.approved');
                        order.statusStyle = 'approved';
                    } else if (order.status == 'CANCELLED') {
                        order.status = t('orders-table.status.refused');
                        order.statusStyle = 'refused';
                    } else {
                        order.status = t('orders-table.status.pending');
                        order.statusStyle = 'pending';
                    }
                })
                
                onSetArrayFiltered(ordersFiltered);
            }
        }
        catch {
            showToast('error', t('toast-message.orders.filtre.error'));
        }
        finally {
            onSetLoading(false);
        }
    }

    const filterArray = (filter) => {
        switch (type) {
            case 'shelfType':
                return filterShelfType(filter);
            case 'category':
                return filterCategory(filter);
            case 'status':
                return filterStatus(filter);
            default:
                return console.log('Error to filter'); 
        }
    }

    const removeFilter = () => {
        try {
            refresh();
        }
        catch {
            console.log('Error to remove filter');
        }
        finally {
            setIsActive(false);
            onIsFilter(false);
        }
    }

    return (
        <button 
            id={ isActive ? 'active-filter' : null }
            disabled={ isActive ? false : isFilter }
            className={ styles + ( !isActive && isFilter ? ' disabled' : '')} 
            onClick={
                () => isActive 
                ? removeFilter()
                : filterArray(`${filter}`)
            }
            > { isActive ? <AiOutlineClose /> : title }
        </button>
    )
}

export default ButtonFilterComponent