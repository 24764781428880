import './loginScreen.css';
import React, { useState } from 'react';
import ROUTES_NAME from 'routes/routeName.js'; 
import navigateScreen from 'utils/navigateScreen.js';
import NavLoginComponent from 'components/navLoginComponent.js';
import { useTranslation } from 'react-i18next';
import { showToast, ToastComponent } from 'utils/showToast.js';
import LoadingComponent from 'components/loadingComponent';
import authService from 'services/authenticationService.js';

const LoginScreen = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const signIn = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setDisabledButton(true);

    try {
      const credentials = {
        email: event.target.email.value,
        password: event.target.password.value,
      }
      
      await authService.singIn(credentials);

      showToast('success', t('toast-message.login.success'));
      setTimeout(() => {
        navigateScreen(ROUTES_NAME.HOME);
      }, 2500);
    }
    catch (error) {
      showToast('error', t('toast-message.login.error'));
      setDisabledButton(false);
    } 
    finally {
      setIsLoading(false);
    }
  };

  const forgotPassword = () => navigateScreen(ROUTES_NAME.FORGOT_PASSWORD);

  return  (
    <NavLoginComponent
      screen={
      <>
        <ToastComponent />
        <form className='form-login' onSubmit={signIn}>
        { 
          isLoading  
          ? <LoadingComponent/>
          : <>
              <input
                name='email'
                className='input-login mb-3'
                type='text' 
                placeholder='E-mail' 
                required
              />
              <input
                name='password'
                className='input-login mb-5'
                type='password'
                placeholder={t('login.password')}
                required
              />
            </>
        }
          <button disabled={disabledButton} className='submit-button mb-3' type='submit'>{t('login.signin')}</button>
          {/* <button disabled={disabledButton} className='forgot-password' onClick={forgotPassword}>{t('login.forgot-password')}</button> */}
        </form>
      </>
      }
    />
  );
};

export default LoginScreen;