import React, { useEffect, useState } from 'react';
import './homeScreen.css'
import NavHomeComponent from 'components/navHomeComponent';
import GraphComponent from './components/graphComponent.js';
import Table from 'react-bootstrap/Table';
import StatusOrderButton from 'components/statusOrderButtonComponent.js';
import { useTranslation } from 'react-i18next';
import ButtonSortComponent from 'components/buttonSortComponent';
import { ToastComponent, showToast } from 'utils/showToast';
import orderService from 'services/orderService';
import LoadingComponent from 'components/loadingComponent';

const HomeScreen = () => {

  const { t } = useTranslation();
  
  const [report, setReport] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

    const getReport = async () => {
        setIsLoading(true);

        try {
            const response = await orderService.getReport();
            
            setReport(response);
        } 
        catch {
            showToast('error', t('toast-message.dashboard.report.error'));
        } 
        finally {
            setIsLoading(false);
        }
    } 

    const getAllOrders = async () => {
        setIsLoading(true);

        try {
            const response = await orderService.getByStatus('pending');
            
            const totalOrdersObject = response.shift();
            const { totalOrdersResponse } = totalOrdersObject;

            response.map((order) => {
                if (order.status == 'FINISHED') {
                    order.status = t('orders-table.status.approved');
                    order.statusStyle = 'approved';
                } else if (order.status == 'CANCELLED') {
                    order.status = t('orders-table.status.refused');
                    order.statusStyle = 'refused';
                } else {
                    order.status = t('orders-table.status.pending');
                    order.statusStyle = 'pending';
                }
            })
            
            setOrders(response);
            setTotalOrders(totalOrdersResponse);
        } 
        catch {
            showToast('error', t('toast-message.orders.get.error'));
        } 
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getReport();
        getAllOrders();
     }, []);

    const userOrdersScreen = () => navigateScreen(ROUTES_NAME.USER_ORDERS);

    return (
        <NavHomeComponent
        screen={
            <section className='section-home'>
            <ToastComponent />
            {
                isLoading
                ? <LoadingComponent />
                : <GraphComponent 
                    othersMonth={report.totalThreeMonthsAgo}
                    lastMonth={report.totalLastMonth}
                    thisMonth={report.totalCurrentMonth}
                />
            }
            <div className='table-pagination'>
                <h1>{t('dashboard.chart.pending-orders')}</h1>
            </div>
            <div className='container-table mt-2'>
            {
                orders.length == 0
                ? <h1 className='title-warning'>{t('orders-screen.orders.empty')}</h1>
                : <Table className='table' id='ordersTable' responsive>
                    <thead>
                        <tr>
                            <th className='width-md'>{t('orders-table.client')} <ButtonSortComponent keyFilter='name' typeFilter='string' arrayToFilter={orders} onSetArrayToFilter={setOrders}/></th>
                            <th className='width-md'>{t('orders-table.cnpj')} </th>
                            <th className='width-md'>{t('orders-table.date')} <ButtonSortComponent keyFilter='date' typeFilter='date' arrayToFilter={orders} onSetArrayToFilter={setOrders}/></th>
                            <th className='width-s'>{t('orders-table.value')} <ButtonSortComponent keyFilter='totalValue' typeFilter='value' arrayToFilter={orders} onSetArrayToFilter={setOrders}/></th>
                            <th className='width-s'>{t('orders-table.status')} <ButtonSortComponent keyFilter='status' typeFilter='string' arrayToFilter={orders} onSetArrayToFilter={setOrders}/></th>
                            <th className='width-xs'>{t('orders-table.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        orders.map((order) => (
                            <tr key={order.id}>
                                <td><a onClick={userOrdersScreen}>{order.name}</a></td>
                                <td>{order.cnpj}</td>
                                <td>{order.date}</td>
                                <td>{order.totalValue}</td>
                                <td>
                                    <card  
                                        className={'status' + ` bgcolor-${order.statusStyle ? order.statusStyle : ''}`}
                                    >
                                        {order.status ? order.status : t('orders-table.status.undefined').toUpperCase()}
                                    </card>
                                </td>
                                {
                                    order.statusStyle == 'pending' && !isLoading 
                                    ? <td>
                                        <a>
                                            <StatusOrderButton orderId={order.id}/>
                                        </a>
                                    </td> 
                                    : <LoadingComponent />
                                }
                            </tr>
                        ))
                    }  
                    </tbody>
                </Table> 
                }
            </div>
            </section>
        }
        />
    )
}

export default HomeScreen
