import { Routes, Route } from 'react-router-dom';
import ROUTES_NAME from './routeName.js';
import LoginForm from 'screens/login/loginScreen.js';
import HomeScreen from 'screens/home/homeScreen.js';
import NotFoundComponent from './screens/notFoundScreen.js';
import OrdersScreen from 'screens/order/ordersScreen.js';
import ProductsScreen from 'screens/product/productsScreen.js';
import InsertProductsScreen from 'screens/product/insert/insertProductScreen.js';
import MassActionsScreen from 'screens/product/insert/massActionsScreen.js';
import ForgotPasswordScreen from 'screens/login/forgotPassword/forgotPasswordScreen.js';
import SettingScreen from 'screens/setting/settingScreen.js';
import DiscountScreen from 'screens/discount/discountScreen.js';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path={ROUTES_NAME.LOGIN} element={<LoginForm />}/>
            <Route path={ROUTES_NAME.HOME} element={<HomeScreen />}/>
            <Route path={ROUTES_NAME.ORDERS} element={<OrdersScreen />}/>
            <Route path={ROUTES_NAME.PRODUCTS} element={<ProductsScreen />}/>
            <Route path={ROUTES_NAME.DISCOUNT} element={<DiscountScreen />}/>
            <Route path={ROUTES_NAME.INSERT_PRODUCTS} element={<InsertProductsScreen />}/>
            <Route path={ROUTES_NAME.MASS_ACTIONS} element={<MassActionsScreen />}/>
            <Route path={ROUTES_NAME.FORGOT_PASSWORD} element={<ForgotPasswordScreen />}/>
            <Route path={ROUTES_NAME.SETTING} element={<SettingScreen />}/>
            <Route path='*' element={<NotFoundComponent />}/>
        </Routes>
    )
}

export default AppRoutes