import React from 'react';
import './styles/navLogin.css';
import HandsBannerImg from 'assets/images/hands-banner.jpg';
import LogoImg from 'assets/images/logo.jpg';

const NavLoginComponent = (props) => {

    return (
        <div className='container'>
            <img className='content' src={HandsBannerImg} alt='hands-banner'/>
            <div id='sidebar'>
                <div className='sidebar-container'>
                    <div className='content-sidebar'>
                        <img className='logo' src={LogoImg} alt='logo'/>
                        {props.screen}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavLoginComponent