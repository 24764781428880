import { useTranslation } from 'react-i18next';
import './styles/buttonSort.css';
import React, { useState } from 'react';
import { TiArrowUnsorted } from 'react-icons/ti';
import { unformatMoney } from 'utils/formatMoney';
import { showToast } from 'utils/showToast';
import { unformatDate } from 'utils/formatDate';

const ButtonSortComponent = ({ keyFilter, typeFilter, arrayToFilter, onSetArrayToFilter }) => {
    const { t } = useTranslation();

    const [sort, setSort] = useState(true);

    let arrayFiltered = [];

    const sortString = () => {
        try {
            const stringSortFilter = sort
            ? (a, b) => a[keyFilter].localeCompare(b[keyFilter])
            : (a, b) => b[keyFilter].localeCompare(a[keyFilter])

            arrayFiltered = [...arrayToFilter].sort(stringSortFilter);

            onSetArrayToFilter(arrayFiltered);
            setSort(!sort);
        }
        catch {
            showToast('error', t('toast-message.table.sort.error'));
        }
    }

    const sortNumber = () => {
        try {
            const numberSortFilter = sort
            ? (a, b) => a[keyFilter] - b[keyFilter]
            : (a, b) => b[keyFilter] - a[keyFilter]

            arrayFiltered = [...arrayToFilter].sort(numberSortFilter);;

            onSetArrayToFilter(arrayFiltered);
            setSort(!sort);
        } 
        catch {
            showToast('error', t('toast-message.table.sort.error'));
        }
    }

    const sortValue = () => {
        try {
            const valueSortFilter = sort
            ? (a, b) => keyFilter == 'totalValue' 
                ? unformatMoney(a[keyFilter]) - unformatMoney(b[keyFilter]) 
                : unformatMoney(a[keyFilter][0]) - unformatMoney(b[keyFilter][0]) 
            : (a, b) => keyFilter ==  'totalValue'
                ? unformatMoney(b[keyFilter]) - unformatMoney(a[keyFilter])
                : unformatMoney(b[keyFilter][0]) - unformatMoney(a[keyFilter][0])

            arrayFiltered = [...arrayToFilter].sort(valueSortFilter);;

            onSetArrayToFilter(arrayFiltered);
            setSort(!sort);
        } 
        catch {
            showToast('error', t('toast-message.table.sort.error'));
        }
    }

    const sortDate = () => {
        try {
            const dateSortFilter = sort
                ? (a, b) => unformatDate(a[keyFilter]).localeCompare(unformatDate(b[keyFilter]))
                : (a, b) => unformatDate(b[keyFilter]).localeCompare(unformatDate(a[keyFilter]))

            arrayFiltered = [...arrayToFilter].sort(dateSortFilter);;

            onSetArrayToFilter(arrayFiltered);
            setSort(!sort);
        } 
        catch {
            showToast('error', t('toast-message.table.sort.error'));
        }
    }
    
    // const sortBoolean = () => {
    //     try {
    //         const booleanSortFilter = sort
    //             ? (a, b) => b[keyFilter] - a[keyFilter] 
    //             : (a, b) => a[keyFilter] - b[keyFilter]

    //         arrayFiltered = [...arrayToFilter].sort(booleanSortFilter);;

    //         onSetArrayToFilter(arrayFiltered);
    //         setSort(!sort);
    //     } 
    //     catch {
    //         showToast('error', t('toast-message.table.sort.error'));
    //     }
    // }

    const sortArray = () => {
        switch (typeFilter) {
            case 'string':
                return sortString();
            case 'number':
                return sortNumber();
            case 'value':
                return sortValue();
            case 'date':
                return sortDate();
            // case 'boolean':
            //     return sortBoolean();
            default:
                return showToast('error', t('toast-message.table.sort.error'));
        }
        // TODO SORT WITH PUBLISHED
    }

    return (
        <button className='button-sort-filter' onClick={sortArray}>
            <TiArrowUnsorted/>
        </button>
    )
} 

export default ButtonSortComponent;