import React, { useState, useEffect } from 'react';
import './styles/navHome.css';
import LogoImg from 'assets/images/logo-ligth.jpeg';
import LogoImgSM from 'assets/images/logo-short.svg';
import ROUTES_NAME from 'routes/routeName.js'; 
import { AiOutlineHome, AiOutlineDollarCircle, AiOutlineShop } from 'react-icons/ai';
import { MdKeyboardArrowDown, MdArrowBackIosNew, MdArrowForwardIos, MdLogout } from 'react-icons/md';
import { IoSettingsOutline, IoGridOutline, IoCreateOutline } from 'react-icons/io5';
import { LuBadgePercent } from "react-icons/lu";
import navigateScreen from 'utils/navigateScreen.js';
import { useTranslation } from 'react-i18next';

const NavHomeComponent = (props) => {
    const [clickedBreakSidebar, setClickedBreakSidebar] = useState(
        JSON.parse(localStorage.getItem('sidebarBreak')) || false
    );
    
    const [clickedProduct, setClickedProduct] = useState(false);

    // Save value to localStorage
    const saveToLocalStorage = () => {
        localStorage.setItem('sidebarBreak', JSON.stringify(clickedBreakSidebar));
    };

    useEffect(() => {
        // Save localStorage value when component is mounted
        window.addEventListener('beforeunload', saveToLocalStorage);

        // Remove event listener to prevent memory leaks
        return () => {
            window.removeEventListener('beforeunload', saveToLocalStorage);
        };
    }, [clickedBreakSidebar]);
    
    const { t } = useTranslation();

    const logOut = () => navigateScreen(ROUTES_NAME.LOGIN);
    const homeScreen = () => navigateScreen(ROUTES_NAME.HOME);
    const productsScreen = () => navigateScreen(ROUTES_NAME.PRODUCTS);
    // const discountProductsScreen = () => navigateScreen(ROUTES_NAME.DISCOUNT);
    const insertProductsScreen = () => navigateScreen(ROUTES_NAME.INSERT_PRODUCTS);
    const ordersScreen = () => navigateScreen(ROUTES_NAME.ORDERS);
    const settingsScreen = () => navigateScreen(ROUTES_NAME.SETTING);

    const breakSidebar = () => {
        setClickedBreakSidebar(!clickedBreakSidebar);
    };

    const dropdown = () => {
        setClickedProduct(!clickedProduct);
    };

    const sidebarStyle = clickedBreakSidebar
        ? {
              left: '50px',
              width: 'calc(100% - 50px)',
              transition: '0.5s',
          }
        : {
              left: '300px',
              width: 'calc(100% - 300px)',
              transition: '0.5s',
          };

    const divDisplayStyle = {
        height: clickedProduct ? '105px' : '0px',
        transition: '0.5s',
    };

    const arrowRotateStyle = clickedProduct
        ? {
              transition: '1s',
              transform: 'rotate(-90deg)',
          }
        : { transition: '1s' };

    return (
        clickedBreakSidebar ? (
            <>
                <div id='sidebar-dashboard-sm'>
                    <div id='sidebar-container-dashboard-sm'>
                        <div className='menu-sm mb-5'>
                            <img width={50} src={LogoImgSM} alt='logo'/>
                            <button onClick={breakSidebar}><MdArrowForwardIos className='icon'/></button>
                        </div>
                        <nav>
                            <ul>
                                <li>
                                    <a className='list-page-sm' onClick={homeScreen}><AiOutlineHome className='icon'/></a>
                                    <a className='list-page-sm'  onClick={dropdown}><AiOutlineShop className='icon'/></a>
                                    <div className='dropdown-list' style={divDisplayStyle}>
                                        <a className='list-page-sm' onClick={productsScreen}><IoGridOutline className='icon'/></a>
                                        {/* <a className='list-page-sm' onClick={discountProductsScreen}><LuBadgePercent className='icon'/></a> */}
                                        <a className='list-page-sm' onClick={insertProductsScreen}><IoCreateOutline className='icon'/></a>
                                    </div>
                                    <a className='list-page-sm' onClick={ordersScreen}><AiOutlineDollarCircle className='icon'/></a>
                                    <a className='list-page-sm' onClick={settingsScreen}><IoSettingsOutline className='icon'/></a>
                                </li>
                                <a className='list-page-sm logout' onClick={logOut}><MdLogout className='icon'/></a>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div id='content-dashboard-sm' style={sidebarStyle}>
                    <main className='container-content'>
                        {props.screen}
                    </main>
                </div>
            </>
        ) : (
            <>
                <div id='sidebar-dashboard'>
                    <div id='sidebar-container-dashboard'>
                        <div className='menu mb-5'>
                            <img width={160} src={LogoImg} alt='logo'/>
                            <button onClick={breakSidebar}><MdArrowBackIosNew className='icon'/></button>
                        </div>
                        <nav>
                            <ul>
                                <li>
                                    <a className='list-page' onClick={homeScreen}><AiOutlineHome className='icon'/>Home</a>
                                    <button onClick={dropdown} className='list-page'><AiOutlineShop className='icon'/><a>{t('nav-home.products')}</a><MdKeyboardArrowDown className='icon ms-1' style={arrowRotateStyle}/></button>
                                    <ul className='dropdown-list' style={divDisplayStyle}>
                                        <a className='list-page ps-4' onClick={productsScreen}><IoGridOutline className='icon'/>{t('nav-home.all-products')}</a>
                                        {/* <a className='list-page ps-4' onClick={discountProductsScreen}><LuBadgePercent className='icon'/>{t('nav-home.discounts-products')}</a> */}
                                        <a className='list-page ps-4' onClick={insertProductsScreen}><IoCreateOutline className='icon'/>{t('nav-home.insert-products')}</a>
                                    </ul>
                                    <a className='list-page' onClick={ordersScreen}><AiOutlineDollarCircle className='icon'/>{t('nav-home.orders')}</a>
                                    <a className='list-page' onClick={settingsScreen}><IoSettingsOutline className='icon'/>{t('nav-home.settings')}</a>
                                </li>
                                <a className='list-page logout' onClick={logOut}><MdLogout className='icon text-danger'/>{t('nav-home.get-out')}</a>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div id='content-dashboard' style={sidebarStyle}>
                    <main className='container-content'>
                        {props.screen}
                    </main>
                </div>
            </>
        )
    );
};

export default NavHomeComponent;