import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ErrorBoundary } from 'react-error-boundary';
import AppRoutes from './routes/appRoutes.js';
import NotFoundComponent from './routes/screens/notFoundScreen.js';
import './App.css';

const browserHistory = createBrowserHistory();

function ErrorFallback() {
  return (<NotFoundComponent />)
}

function App() {
  return (
    <BrowserRouter history={browserHistory}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppRoutes />
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;