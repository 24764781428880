import client from './client.js'
import ENDPOINTS from './endpoints.js'

const IndustryProfileService = {
    get: async () => {
        const response = await client.get(ENDPOINTS.INDUSTRY_PROFILE);
        const resultResponse = response.data;

        return resultResponse;
    },

    patch: async (industryData) => {
        const formData = new FormData();
        
        if (industryData.image instanceof File) {
            formData.append('image', industryData.image);
        } else {
            delete industryData.image;
        }

        formData.append('name', industryData.name);
        formData.append('description', industryData.description);
        formData.append('minimalOrder', industryData.minimalOrder);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        await client.patch(ENDPOINTS.INDUSTRY_PROFILE, formData, config);
    }
} 

export default IndustryProfileService