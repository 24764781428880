import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enJson from '../assets/languages/en.json'
import ptBRJson from '../assets/languages/ptBR.json'

const LANG_STORAGE_KEY = 'preferredLanguage';

const savePreferredLanguage = (lng) => {
  localStorage.setItem(LANG_STORAGE_KEY, lng);
};

const getPreferredLanguage = () => {
  return localStorage.getItem(LANG_STORAGE_KEY);
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'pt', // language default
        detection: {
            order: ['navigator', 'htmlTag', 'path', 'subdomain'],
            lookupFromPathIndex: 0,
        },
        resources: {
            en: {translation: enJson},
            pt: {translation: ptBRJson},
        },
        lng: getPreferredLanguage() || 'pt'
    });

i18n.on('languageChanged', (lng) => {
    savePreferredLanguage(lng); // save choose language on localStorage
});

export default i18n;