import './tableProductsScreen.css';
import React from 'react';
import ButtonFilterComponent from 'components/buttonSortComponent';
import ActionProductButtonComponent from '../components/actionProductButtonComponent.js';
import Table from 'react-bootstrap/Table';
import ToggleButtonComponent from '../components/toggleButtonComponent.js';
import { useTranslation } from 'react-i18next';
import LoadingComponent from 'components/loadingComponent';
import { formatMoney, unformatMoney } from 'utils/formatMoney';
import navigateScreen from 'utils/navigateScreen';
import ROUTES_NAME from 'routes/routeName';
import DefaultImage from 'assets/images/white-image.jpg';

const TableProductsScreen = ({ products, onSetProducts, isLoading, refresh }) => {
    const { t } = useTranslation();

    const minMaxPrices = (product) => {
        const prices = product.prices.map(item => parseFloat(unformatMoney(item.price))); 

        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);

        return { minPrice, maxPrice };
    };

    const insertProduct = () => {navigateScreen(ROUTES_NAME.INSERT_PRODUCTS)};

    return (
        <div className='container-table mt-4'>
            {
                isLoading
                ? <LoadingComponent />
                : products.length == 0
                    ? <a onClick={insertProduct} className='title-warning'>{t('products-screen.products.empty')}</a>
                    : <Table className='productTable' id='table' responsive>
                        <thead>
                            <tr>
                                <th className='width-s'>{t('products-table.sku-short')} <ButtonFilterComponent keyFilter='sku' typeFilter='number' arrayToFilter={products} onSetArrayToFilter={onSetProducts}/></th>
                                <th className='width-lg'>{t('products-table.name')} <ButtonFilterComponent keyFilter='name' typeFilter='string' arrayToFilter={products} onSetArrayToFilter={onSetProducts}/></th>
                                <th className='width-s'>{t('products-table.weight')} <ButtonFilterComponent keyFilter='weight' typeFilter='number' arrayToFilter={products} onSetArrayToFilter={onSetProducts}/></th>
                                <th className='width-md'>{t('products-table.price')} <ButtonFilterComponent keyFilter='prices' typeFilter='value' arrayToFilter={products} onSetArrayToFilter={onSetProducts}/></th>
                                <th className='width-s'>{t('products-table.quantity')} <ButtonFilterComponent keyFilter='boxQuantity' typeFilter='number' arrayToFilter={products} onSetArrayToFilter={onSetProducts}/></th>
                                <th className='width-s'>{t('products-table.validate')}</th>
                                <th className='width-s'>{t('products-table.published')} <ButtonFilterComponent keyFilter='active' typeFilter='boolean' arrayToFilter={products} onSetArrayToFilter={onSetProducts}/></th>
                                <th className='width-xs'>{t('products-table.actions')}</th>
                            </tr>
                        </thead>
                        <tbody id='tbody'>
                            {
                                products.map((product) => (
                                    <tr>
                                        <td>{product.sku}</td>
                                        <td><img className='image-product' src={product.imageUrl != undefined ? product.imageUrl : DefaultImage} alt='Imagem do produto' />{product.name}</td>
                                        <td>{product.weight} kg</td>
                                        <td>
                                        {
                                            product.prices.length == 0
                                            ? ''
                                            : `${formatMoney(minMaxPrices(product).minPrice)} - ${formatMoney(minMaxPrices(product).maxPrice)}`
                                        }
                                        </td>
                                        <td>{product.boxQuantity} un</td>
                                        <td>
                                            <div className='d-flex'>
                                                <ul className='validates-column'>
                                                    { product.shelfTypes.includes('GREEN') ? <li key={'tableGreen'} className='validate-green'></li> : null}
                                                    { product.shelfTypes.includes('YELLOW') ? <li key={'tableYellow'} className='validate-yellow'></li> : null}
                                                    { product.shelfTypes.includes('ORANGE') ? <li key={'tableOrange'} className='validate-orange'></li> : null}
                                                    { product.shelfTypes.includes('RED') ? <li key={'tableRED'} className='validate-red'></li> : null}
                                                </ul>
                                            </div>
                                        </td>
                                        <ToggleButtonComponent nameRef={'active'} toggleValue={product} setToggleValues={onSetProducts} refreshList={() => refresh()} />
                                        <td><ActionProductButtonComponent actionId={product.id} refreshList={() => refresh()} skuProduct={product.sku} /></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
            }
        </div>
    )
}

export default TableProductsScreen
