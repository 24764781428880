import client from './client.js'
import ENDPOINTS from './endpoints.js'

const AuthenticationService = {
    singIn: async (credentials) => {
        const response = await client.post(ENDPOINTS.SIGN_IN, credentials);
        
        const { accessToken, refreshToken } = response.data;
        
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        
        if (response.status == 200) {
            localStorage.setItem('accessToken', JSON.stringify(accessToken));
            localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
        }
    }
}

export default AuthenticationService