function unformatDate(formattedDate) {
    const datePart = removeTimeFromFormattedDate(formattedDate);
    const [day, month, year] = datePart.split('/');
    return new Date(Date.UTC(year, month - 1, day)).toISOString().split('T')[0];
}

function formatDate(stringDate) {
    const date = new Date(stringDate);
    const formattedDate = `${String(date.getUTCDate()).padStart(2, '0')}/${String(date.getUTCMonth() + 1).padStart(2, '0')}/${date.getUTCFullYear()}`;
    return formattedDate;
}

function dateFormartISO(date) {
    var datePart = date.split('/');
  
    var dateObj = new Date(datePart[2], datePart[1] - 1, datePart[0]);
  
    var year = dateObj.getFullYear();
    var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    var day = ('0' + dateObj.getDate()).slice(-2);
  
    var formatISO = year + '-' + month + '-' + day + 'T00:00:00.000Z';
  
    return formatISO;
  }

export { formatDate, unformatDate, dateFormartISO };