import numeral from 'numeral';
import 'numeral/locales/en-au';
import 'numeral/locales/pt-br';

const language = localStorage.getItem('preferredLanguage');
if (language == 'pt') {
  numeral.locale('pt-br');
} else {
  numeral.locale('en-au');
}

function formatMoney(value) {
  return numeral(value).format('$ 0,0.00');
}

function unformatMoney(formattedValue) {
  return numeral(formattedValue).value();
}

export { formatMoney, unformatMoney };
