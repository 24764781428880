import client from './client.js'
import ENDPOINTS from './endpoints.js'

const PriceService = {
    post: async (productId, prices, published) => {
        if (prices.length > 0) {
            prices.forEach( async (price) => {
                price.productId = productId;
                price.active = published;
                
                client.post(ENDPOINTS.PRICES, price);
            });
        }
    },

    patch: async (arrayId, isActive) => {
        arrayId.forEach(idPrice => {
            client.patch(ENDPOINTS.PRICES_ID + idPrice, { 'active' : isActive });
        });
    },

    delete: async (arrayId) => {
        arrayId.forEach(idPrice => {
            client.delete(ENDPOINTS.PRICES_ID + idPrice);
        });
    }
}

export default PriceService