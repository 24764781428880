import React from 'react';
import { FiCheckCircle } from 'react-icons/fi';

const CheckPasswordComponent = ({ title }) => {

  return (
      <div className='d-flex mb-3'>
          <FiCheckCircle className='text-success' />
          <p style={{'margin-left': 10}}>{title}</p>
      </div>
  )
}

export default CheckPasswordComponent
