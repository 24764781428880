import React, { useEffect, useState } from 'react';
import NavHomeComponent from 'components/navHomeComponent.js';
import './insertProductScreen.css';
import DropImageInput from 'components/dropImageInputComponent';
import ToggleButtonComponent from '../components/toggleButtonComponent';
import navigateScreen from 'utils/navigateScreen';
import ROUTES_NAME from 'routes/routeName';
import { useTranslation } from 'react-i18next';
import { ToastComponent, showToast } from 'utils/showToast';
import LoadingComponent from 'components/loadingComponent';
import productService from 'services/productService';
import categoryService from 'services/categoryService';
import { MdKeyboardArrowDown } from 'react-icons/md';
import CardPricesScreen from './prices/cardPricesScreen';
import { PiCardsLight, PiTableLight } from 'react-icons/pi';
import TablePricesScreen from './prices/tablePricesScreen';
import ImportExcelComponent from './importExcelComponent.js';

const InsertProductsScreen = () => {
    const { t } = useTranslation();  
    
    let pricesList = [];

    const [excelData, setExcelData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [pricesInputFilled, setPricesInputFilled] = useState(false);
    const [clickedCategory, setClickedCategory] = useState(false);
    const [categories, setCategories] = useState([]);
    const [idsPricesDelete, setIdsPricesDelete] = useState([]);

    const [cardPrice, setCardPrice] = useState(() => {
        const storedValue = localStorage.getItem('typePrices');
        return storedValue ? JSON.parse(storedValue) : false;
    });

    const [formData, setFormData] = useState({
        image: '',
        name: '',
        prices: [],
        weight: '',
        boxQuantity: '',
        category: '',
        sku: '',
        variableWeight: false,
        published: true
    });

    const [priceValues, setPriceValues] = useState({
        price: '',
        expirationDatetime: '',
        manufacturingDatetime : '',
        minimalOrder: ''
    });


    const saveToLocalStorage = () => {
        localStorage.setItem('typePrices', JSON.stringify(cardPrice));
    };

    const getByIdProduct = async (id) => {
        setIsLoading(true);

        try {
            const response = await productService.getById(id);
            setIsEdit(true);

            const productsData = await response.map(product => ({
                id: product.id,
                image: product.image,
                name: product.name,
                prices: product.prices,
                weight: parseFloat(product.weight),
                boxQuantity: parseInt(product.boxQuantity),
                category: product.category,
                sku: product.sku,
                variableWeight: product.variableWeight,
                published: product.published
            }));

            setFormData(productsData[0]);
        } 
        catch {
            showToast('error', t('toast-message.insert-products.screen.error'));
        } 
        finally {
            setIsLoading(false);
        }
    }

    const getCategories = async () => {
        setIsLoading(true);

        try {
            const response = await categoryService.get();

            setCategories(response);
        } 
        catch {
            showToast('error', t('toast-message.insert-products.screen.error'));
        } 
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        window.addEventListener('beforeunload', saveToLocalStorage);

        try {
            const url = window.location.href;
            const arrayHref = url.split('?');
            const id = arrayHref[1];
            
            if (id != undefined && id != '') {
                getByIdProduct(id);
            }

            getCategories();
        }
        catch {
            showToast('error', t('toast-message.insert-products.screen.initialize.error'));
        }

        return () => {
            window.removeEventListener('beforeunload', saveToLocalStorage);
        };
    }, [cardPrice]);

    const submitProduct = () => {
        return isEdit ? updateProduct() : postProduct();
    }

    const postProduct = async () => {
        if (excelData.length > 0) {
            setIsLoading(true);

            try {
                await productService.postBatch(excelData);
                showToast('success', t('toast-message.products.post-excel.success'));
            }
            catch {
                showToast('error', t('toast-message.products.post-excel.error'));
            }
            finally {
                setIsLoading(false);
            }
        }

        if (
        formData.image != ''
        && formData.name != ''
        && formData.boxQuantity != 0
        && formData.category != ''
        && formData.sku != ''
        ) {
            const formDataProduct = await {
                'image': formData.image,
                'name': formData.name,
                'prices': formData.prices,
                'weight': parseFloat(formData.weight),
                'boxQuantity': parseInt(formData.boxQuantity),
                'category': formData.category,
                'sku': formData.sku,
                'grammage': 200,
                'variableWeight': formData.variableWeight,
                'published': formData.published
            }

            try {
                setIsLoading(true);
                await productService.post(formDataProduct);

                showToast('success', t('toast-message.products.post.success'));
                
                setTimeout(() => {
                    navigateScreen(ROUTES_NAME.INSERT_PRODUCTS);
                }, 500);
            }
            catch {
                showToast('error', t('toast-message.products.post.error'));
            }
            finally {
                setIsLoading(false);
            }
        } else {
            showToast('error', t('toast-message.insert-products.screen.post.validate.error'))
        }
    }

    const updateProduct = async () => {
        if (
        formData.id != ''
        && formData.image != ''
        && formData.name != ''
        && formData.boxQuantity > 0
        && formData.category != ''
        && formData.sku != ''
        ) {
            let formDataProduct = await {
                'id': formData.id,
                'image': formData.image,
                'name': formData.name,
                'prices': formData.prices,
                'pricesDelete': idsPricesDelete,
                'weight': parseFloat(formData.weight),
                'boxQuantity': parseInt(formData.boxQuantity),
                'category': formData.category,
                'sku': formData.sku,
                'grammage': 200,
                'variableWeight': formData.variableWeight,
                'published': formData.published
            }

            try {
                setIsLoading(true);
                await productService.patch(formDataProduct);

                showToast('success', t('toast-message.products.patch.success'));
                setTimeout(() => {
                    refresh();
                }, 500);
            }
            catch {
                showToast('error', t('toast-message.products.patch.error'));
            }
            finally {
                setIsLoading(false);
            }
        } else {
            showToast('error', t('toast-message.insert-products.screen.post.validate.error'))
        }
    }

    const refresh = () => window.location.reload();
    const massActionsScreen = () => navigateScreen(ROUTES_NAME.MASS_ACTIONS);

    const addPriceList = () => {
       if (
        priceValues.price != '' && priceValues.price > 0 
        && priceValues.manufacturingDatetime != ''
        && priceValues.expirationDatetime != ''
        && priceValues.minimalOrder != ''
        ) {
            try {
                const price = priceValues.price.replace(/[.,]/g, '');
                const minimalOrder = priceValues.minimalOrder.replace(/[.,]/g, '');

                pricesList.push({
                    'price': parseFloat(price),
                    'manufacturingDatetime': priceValues.manufacturingDatetime,
                    'expirationDatetime': priceValues.expirationDatetime,
                    'batchCode': 0,
                    'minimalOrder': parseFloat(minimalOrder)
                });

                const updatePrices = [...formData.prices, pricesList[0]];

                setFormData(prevState => ({
                    ...prevState,
                    prices: updatePrices
                }));
            }
            catch {
                showToast('error', t('toast-message.insert-products.screen.add-price.post.error'));
            }
            finally {
                setPriceValues(prevState => ({
                    ...prevState,
                    price: '',
                    expirationDatetime: '',
                    manufacturingDatetime: '',
                    minimalOrder: '',
                }));
                setPricesInputFilled(false);
            }
       } else {
            showToast('error', t('toast-message.insert-products.screen.add-price.validate.error'));
       }
    }

    const valueChange = (e, name) => {
        let value = e.target.value;

        // Remove everything that is not a number
        value = value.replace(/\D/g, '');

        // Format the price with two decimal places
        if (value.length > 2) {
            const min = value.slice(-2);
            const max = value.slice(0, -2);
            value = `${max}.${min}`;
        }

        setPriceValues({ ...priceValues, [name]: value });
    }

    const weightChange = (e) => {
        let inputValue = e.target.value;

        // Remove everything that is not a number
        let formattedValue = inputValue.replace(/[^0-9]/g, '');

        // Format the price with two decimal places
        if (formattedValue.length >= 2) {
            formattedValue = formattedValue.slice(0, 1) + '.' + formattedValue.slice(1);
        }
        
        setFormData({...formData, weight: formattedValue});
    };

    const dropdowCategories = () => {
        setClickedCategory(!clickedCategory);
    }

    const changeCategory = (categoryName) => {
        setClickedCategory(!clickedCategory);

        setFormData({ ...formData, category: categoryName});
    }

    const isCardPrice = () => {
        setCardPrice(prevCardPrice => !prevCardPrice);
        
        setPriceValues(prevState => ({
            ...prevState,
            price: '',
            expirationDatetime: '',
            manufacturingDatetime: '',
            minimalOrder: '',
        }));
        setPricesInputFilled(false);

    };

    const arrowRotateStyle = clickedCategory
        ? { transition: '1s' }
        : {
            transition: '1s',
            transform: 'rotate(-90deg)'
        }

    return (
        <NavHomeComponent
            screen={
            <>
                <ToastComponent />
                <div className='header-actions'>
                    <button className='button-page-header'>{t('products-screen.insert-products')}</button>
                    {/* <button className='button-page-header ms-1' onClick={massActionsScreen}>{t('products-screen.mass-actions-products')}</button> */}
                </div>
                <form 
                    className='form-insert-product'>
                    {
                        isLoading
                        ? <LoadingComponent />
                        : <>
                        <div className='content-insert-product'>
                            <div className='div-input'>
                                <h1>{t('products-table.create-product')}</h1>
                                <div>
                                    <div>
                                        <label for='sku'>{t('products-table.sku')}</label>
                                        <input
                                            className='input-insert-product'
                                            type='text' 
                                            name='sku'
                                            value={formData.sku}
                                            onChange={(e) => setFormData({ ...formData, sku: e.target.value.replace(/\D/g, '')})}
                                            required />
                                    </div>
                                    <div>
                                        <label for='category'>{t('products-table.category')}</label>
                                        <div className='div-categories'>
                                            <input
                                                className='input-insert-product'
                                                type='text' 
                                                name='category'
                                                value={formData.category}
                                                onChange={(e) => setFormData({ ...formData, category: e.target.value})}
                                                required />
                                            {
                                                categories.length > 0
                                                ? <button className='drop-categories' type='button' onClick={() => dropdowCategories()}><MdKeyboardArrowDown style={arrowRotateStyle}/></button>
                                                : null
                                            }
                                        </div>
                                        {
                                            categories.length > 0 && clickedCategory
                                            ? <ul className='list-categories'>
                                                {
                                                categories.map((category) => (
                                                    <li key={category.id}><button type='button' className='category-button' onClick={() => changeCategory(category.name)}>{category.name}</button></li>
                                                ))    
                                                }
                                            </ul>
                                            : null
                                        }
                                    </div>
                                </div>
                                <label className='mt-2' for='name'>{t('products-table.description')}</label>
                                <input
                                    className='input-insert-product'
                                    type='text' 
                                    name='name'
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value})}
                                    required />
                                <div>
                                    <div>
                                        <label for='weight'>
                                        {
                                            formData.variableWeight 
                                            ? t('products-table.weight-per-piece')
                                            : t('products-table.weight-per-unit')
                                        }
                                        </label>
                                        <input
                                            className='input-insert-product'
                                            type='text' 
                                            name='weight'
                                            value={formData.weight}
                                            onChange={weightChange}
                                            required />
                                    </div>
                                    <div>
                                        <label for='quntity'>{t('products-table.quantity-per-box')}</label>
                                        <input
                                            className='input-insert-product'
                                            type='text' 
                                            name='quantity'
                                            value={formData.boxQuantity}
                                            onChange={(e) => setFormData({ ...formData, boxQuantity: e.target.value.replace(/\D/g, '')})}
                                            required />
                                    </div>
                                </div>
                                <div>
                                    <div className='toggles'>
                                        <p>{t('products-table.variableWeight')}</p>
                                        <ToggleButtonComponent nameRef={'variableWeight'} toggleValue={formData} setToggleValues={setFormData}/>
                                    </div>
                                    <div className='toggles'>
                                        <p>{t('products-table.published')}</p>
                                        <ToggleButtonComponent nameRef={'published'} toggleValue={formData} setToggleValues={setFormData}/>
                                    </div>
                                </div>
                            </div>
                            <DropImageInput formData={formData} setFormData={setFormData} />
                        </div>
                        <div className='div-prices'>
                            <h1>{t('products-table.create-price')}</h1>
                            <div className='div-input-prices'>
                                <div>
                                    <label for='price'>
                                    {
                                        formData.variableWeight
                                        ? t('products-table.price-per-weight')
                                        : t('products-table.price-per-unit')
                                    }
                                    </label>
                                    <input
                                        className='input-insert-product'
                                        type='text' 
                                        name='price'
                                        value={priceValues.price}
                                        onChange={(e) => valueChange(e, 'price')}/>
                                </div>
                                <div>
                                    <label for='manufacturingDatetime'>{t('products-table.price.manufacturing-date-time')}</label>
                                    <input
                                        className='input-insert-product'
                                        type='date' 
                                        name='manufacturingDatetime'
                                        value={priceValues.manufacturingDatetime}
                                        onChange={(e) => setPriceValues({ ...priceValues, manufacturingDatetime: e.target.value })}/>
                                </div>
                                <div>
                                    <label for='expirationDatetime'>{t('products-table.price.expiration-date-time')}</label>
                                    <input
                                        className='input-insert-product'
                                        type='date' 
                                        name='expirationDatetime'
                                        value={priceValues.expirationDatetime}
                                        onChange={(e) => setPriceValues({ ...priceValues, expirationDatetime: e.target.value })}/>
                                </div>
                                <div>
                                    <label for='minimalOrder'>{t('products-table.price.minimal-order')}</label>
                                    <input
                                        className='input-insert-product'
                                        type='text' 
                                        name='minimalOrder'
                                        value={priceValues.minimalOrder}
                                        onChange={(e) => valueChange(e, 'minimalOrder')}/>
                                </div>
                            </div>
                            <button className='add-price-button' onClick={() => addPriceList()} type='button'>{ t('buttons.insert-price') }</button>
                            <div className='div-prices-show'>
                                {
                                    formData.prices.length == 0 
                                    ? null
                                    : <button className='switch-show' type='button' onClick={isCardPrice}>
                                        { 
                                            cardPrice 
                                            ? <PiTableLight />
                                            : <PiCardsLight /> 
                                        }
                                    </button>
                                }
                                {
                                    cardPrice
                                    ? <CardPricesScreen prices={formData.prices} onSetPriceValues={setPriceValues} inputFilled={pricesInputFilled} onInputFilled={setPricesInputFilled} idsPricesDelete={idsPricesDelete} onSetIdsPricesDelete={setIdsPricesDelete} onSetFormData={setFormData}/>
                                    : <TablePricesScreen prices={formData.prices} onSetPriceValues={setPriceValues} inputFilled={pricesInputFilled} onInputFilled={setPricesInputFilled} idsPricesDelete={idsPricesDelete} onSetIdsPricesDelete={setIdsPricesDelete} onSetFormData={setFormData}/>
                                }
                            </div>
                        </div>
                        {
                            isEdit 
                            ? null 
                            : <>
                                <div className='divider'><span></span> <p>{t('indicative.or')}</p> <span></span></div>
                                <ImportExcelComponent onSetExcelData={setExcelData} />
                            </>
                        }
                        <footer className='footer-insert-products'>
                            <button disabled={isLoading} className='button-cancel' type='button' onClick={() => refresh()}>{t('buttons.cancel')}</button>
                            <button disabled={isLoading} className='button-save' type='button' onClick={() => submitProduct()}>{t('buttons.save')}</button>
                        </footer>
                        </>
                    }
                </form>
            </>
        }/>
    )
}

export default InsertProductsScreen
