import React, { useEffect, useState } from 'react';
import NavHomeComponent from 'components/navHomeComponent.js';
import DropImageInput from 'components/dropImageInputComponent.js';
import './settingScreen.css'
import { useTranslation } from 'react-i18next';
import ToogleLanguageButton from './components/toogleLanguageButton.js'
import industryProfileService from 'services/industryProfileService';
import { ToastComponent, showToast } from 'utils/showToast';
import LoadingComponent from 'components/loadingComponent';

const SettingScreen = () => {
    const { t } = useTranslation();
    
    const [industryData, setIndustryData] = useState({
        image: '',
        name: '',
        email: '',
        description: '',
        minimalOrder: 0,
    });

    const [isLoading, setIsLoading] = useState(false);

    const getIndustryProfile = async () => {
        setIsLoading(true);

        try {
            const response = await industryProfileService.get();

            const industryDataRes = {
                image: response.image_url,
                name: response.name,
                email: response.email,
                description: response.description,
                minimalOrder: (response.minimalOrder / 100).toFixed(2),
            };

            setIndustryData(industryDataRes);
        }
        catch {
            showToast('error', t('settings.get.error'));
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getIndustryProfile();
    }, []);

    const patchIndustry = async () => {
        setIsLoading(true);
        if (industryData.name != '' && parseFloat(industryData.minimalOrder) >= 0) {
            try {
                const minimalOrder = industryData.minimalOrder.replace(/[.,]/g, '');

                const formDataIndustry = {
                    'name' : industryData.name,
                    'image' : industryData.image,
                    'description' : industryData.description,
                    'minimalOrder' : parseInt(minimalOrder)
                };

                await industryProfileService.patch(formDataIndustry);
                showToast('success', t('toast-message.settings-industry.patch.success'));
            }
            catch {
                showToast('error', t('toast-message.settings-industry.patch.error'));
            }
            finally {
                setIsLoading(false);
                await getIndustryProfile();
            }
        }
    }

    const valueChange = (e) => {
        let value = e.target.value;

        // Remove everything that is not a number
        value = value.replace(/\D/g, '');

        // Format the price with two decimal places
        if (value.length > 2) {
            const min = value.slice(-2);
            const max = value.slice(0, -2);
            value = `${max}.${min}`;
        }

        setIndustryData({ ...industryData, minimalOrder: value});
    }

    const refresh = () => window.location.reload();

    return (
        <NavHomeComponent 
            screen={
                <section className='section-setting'>
                    <ToastComponent />
                {
                    isLoading
                    ? <LoadingComponent />
                    : 
                    <div className='d-flex'>
                        <form 
                            className='content-setting'
                            // onSubmit={}
                            >
                            <div>
                                <label className='label-setting' for='name'>{t('settings-screen.industry.name')}</label>
                                <input
                                    className='input-setting'
                                    type='text' 
                                    name='name'
                                    value={industryData.name}
                                    onChange={(e) => setIndustryData({ ...industryData, name: e.target.value})}     
                                    placeholder={t('settings-screen.industry.name')}
                                    required />
                            </div>
                            <div className='info-setting'>
                                <div>
                                    <label className='label-setting' for='email'>{t('settings-screen.industry.email')}</label>
                                    <input
                                        className='input-setting'
                                        type='text' 
                                        name='email'
                                        value={industryData.email}
                                        placeholder={t('settings-screen.industry.email')}
                                        disabled />
                                </div>
                                <div>
                                    <label className='label-setting' for='minimalOrder'>{t('settings-screen.industry.min-value')}</label>
                                    <div className='d-flex'>
                                        <p className='label-value'>R$</p>
                                        <input
                                            className='input-setting border-value'
                                            type='text' 
                                            name='minimalOrder'
                                            value={industryData.minimalOrder}
                                            onChange={(e) => valueChange(e)}          
                                            placeholder={t('settings-screen.industry.min-value')}
                                            required />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className='label-setting' for='description'>{t('settings-screen.industry.description')}</label>
                                <textarea 
                                    className='input-description' 
                                    name='description'
                                    value={industryData.description}
                                    onChange={(e) => setIndustryData({ ...industryData, description: e.target.value})}
                                    placeholder={t('settings-screen.industry.description')} 
                                    rows='4' />
                            </div>
                        </form>
                        <div className='actions-settings'>
                            <DropImageInput formData={industryData} setFormData={setIndustryData} />
                            <ToogleLanguageButton />
                        </div>
                    </div>
                    
                }
                    <div className='footer-settings'>
                        <button className='button-cancel' type='button' onClick={refresh}>{t('buttons.cancel')}</button>
                        <button className='button-save' type='button' onClick={patchIndustry}>{t('buttons.save')}</button>
                    </div>
                </section>
            }
        />
    )
}

export default SettingScreen