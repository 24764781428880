import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import LoadingComponent from 'components/loadingComponent';
import { addDays, format } from 'date-fns';

const ExcelDropzone = ({ onFileUpload, dropMessage, dropMessageDragActive }) => {
    const onDrop = (acceptedFiles) => {
      const file = acceptedFiles[0];
      onFileUpload(file);
    };
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  
    return (
      <div {...getRootProps()} className='excel-dropzone'>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{ dropMessageDragActive }</p>
        ) : (
          <p>{ dropMessage }</p>
        )}
      </div>
    );
};

const ImportExcelComponent = ({ onSetExcelData }) => {
    const { t } = useTranslation();

    const [excelData, setExcelData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleFileUpload = (file) => {
        setIsLoading(true);
    
        try {
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
                delete jsonData[0];
                const arrayJsonProductsWithoutEmptyData = jsonData.filter(products => products && products.length > 0);

                arrayJsonProductsWithoutEmptyData.forEach(products => {
                    const manufacturingDatetime = format(addDays(new Date((products[8] - 25569) * 86400 * 1000), 1), 'dd/MM/yyyy');
                    const expirationDatetime = format(addDays(new Date((products[9] - 25569) * 86400 * 1000), 1), 'dd/MM/yyyy');

                    products[8] = manufacturingDatetime;
                    products[9] = expirationDatetime;
                });

                onSetExcelData(arrayJsonProductsWithoutEmptyData);
                setExcelData(jsonData);
                setIsLoading(false);
            };
    
            reader.readAsBinaryString(file);
        } 
        catch {
            showToast('error', t('toast-message.products.post.error')); 
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        isLoading
        ? <LoadingComponent />
        : <div className='w-100'>
            {excelData.length > 0 ? null : <ExcelDropzone onFileUpload={handleFileUpload} dropMessage={t('drop-table.message')} dropMessageDragActive={t('drop-table.message-drag-active')}/>}
            {excelData.length == 0 ? null : (
                <div className='container-table'>
                    <Table id='table' responsive>
                        <thead>
                        <tr>
                            <th className='width-s'>{t('products-table.sku-short')}</th>
                            <th className='width-md'>{t('products-table.category')}</th>
                            <th className='width-lg'>{t('products-table.description')}</th>
                            <th className='width-xs'>{t('products-table.weight')}</th>
                            <th className='width-xs'>{t('products-table.quantity')}</th>
                            <th className='width-xs'>{t('products-table.weight-per-unit')}</th>
                            <th className='width-xs'>{t('products-table.published')}</th>
                            <th className='width-lg'>{t('products-table.price')}</th>
                            <th className='width-lg'>{t('products-table.price.manufacturing-date-time')}</th>
                            <th className='width-lg'>{t('products-table.price.expiration-date-time')}</th>
                            <th className='width-s'>{t('products-table.price.minimal-order')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {excelData.slice(0).map((row, rowIndex) => (
                            <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>    
    )
}

export default ImportExcelComponent