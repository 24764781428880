import React from 'react';
import { formatMoney } from 'utils/formatMoney';
import { formatDate } from 'utils/formatDate';
import { useTranslation } from 'react-i18next';
import { IoMdTrash, IoMdCreate  } from 'react-icons/io';

const CardPricesScreen = ({ prices, onSetPriceValues, idsPricesDelete, inputFilled, onInputFilled, onSetIdsPricesDelete, onSetFormData }) => {
    const { t } = useTranslation();  

    const editPriceList = (index, priceId, price, manufacturingDatetime, expirationDatetime, minimalOrder) => {
        try {
            const regex = /^(\d{4}-\d{2}-\d{2}).*/;

            let manufacturingDatetimeFormatted = manufacturingDatetime.match(regex);
            let expirationDatetimeFormatted = expirationDatetime.match(regex);

            onSetPriceValues(prevState => ({
                ...prevState,
                price: (price / 100).toFixed(2),
                manufacturingDatetime: manufacturingDatetimeFormatted[1],
                expirationDatetime: expirationDatetimeFormatted[1],
                minimalOrder: (minimalOrder / 100).toFixed(2),
            }));

            if (priceId) {
                const newProductDelete = [...idsPricesDelete];

                newProductDelete.push(priceId);

                onSetIdsPricesDelete(newProductDelete);
            }

            const updatedPrices = [...prices];

            updatedPrices.splice(index, 1);

            onSetFormData(prevState => ({
                ...prevState,
                prices: updatedPrices
            }));
        }
        catch {
            showToast('error', t('toast-message.insert-products.screen.add-price.remove.error'));
        }
        finally {
            onInputFilled(true);
        }
    }

    const removePriceList = (index, priceId) => {
        try {
            if (priceId) {
                const newProductDelete = [...idsPricesDelete];

                newProductDelete.push(priceId);

                onSetIdsPricesDelete(newProductDelete);
            }

            const updatedPrices = [...prices];

            updatedPrices.splice(index, 1);

            onSetFormData(prevState => ({
                ...prevState,
                prices: updatedPrices
            }));
        }
        catch {
            showToast('error', t('toast-message.insert-products.screen.add-price.remove.error'));
        }
    }

    return (
        <ul className='ul-prices'>     
        {
            prices.map((price, index) => (
                <li key={index} className='list-prices'>
                    <div className='actions-prices'>
                        <button disabled={inputFilled} type='button' onClick={() => editPriceList(index, price.id, price.price, price.manufacturingDatetime, price.expirationDatetime, price.minimalOrder)}><IoMdCreate /></button>
                        <button type='button' onClick={() => removePriceList(index, price.id)}><IoMdTrash /></button>
                    </div>
                    <div>
                        <h2>{t('products-table.price')}</h2>
                        <p>{formatMoney(price.price / 100)}</p>
                    </div>
                    <div>
                        <h2>{t('products-table.price.manufacturing-date-time')}</h2>
                        <p>{formatDate(price.manufacturingDatetime)}</p>
                    </div>
                    <div>
                        <h2>{t('products-table.price.expiration-date-time')}</h2>
                        <p>{formatDate(price.expirationDatetime)}</p>
                    </div>
                    <div>
                        <h2>{t('products-table.price.minimal-order')}</h2>
                        <p>{formatMoney(price.minimalOrder / 100)}</p>
                    </div>
                </li>
            ))
        }
        </ul>
    )
}

export default CardPricesScreen