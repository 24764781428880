import React from 'react';
import NavHomeComponent from 'components/navHomeComponent.js';
import './massActionsScreen.css';
import navigateScreen from 'utils/navigateScreen';
import ROUTES_NAME from 'routes/routeName';
import Table from 'react-bootstrap/Table';
import ButtonFilterComponent from 'components/buttonSortComponent';
import ToggleButtonComponent from '../components/toggleButtonComponent';
import { useTranslation } from 'react-i18next';

const MassActionsScreen = () => {

    const { t } = useTranslation();
    const insertProductsScreen = () => {navigateScreen(ROUTES_NAME.INSERT_PRODUCTS)}

    return (
        <NavHomeComponent
            screen={
            <>
                <div className='header-actions'>
                <button className='button-page-header' onClick={insertProductsScreen}>{t('products-screen.insert-products')}</button>
                    <button className='button-page-header ms-1'>{t('products-screen.mass-actions-products')}</button>
                </div>
                <section className='section-mass-action-product'>
                    <div className='content-mass-action-product'>
                        <div className='div-input-mass-action'>
                            <input
                                className='input-insert-product'
                                type='text' 
                                name='name'
                                // onChange={this.typeInput}         
                                placeholder={t('products-table.name')} 
                                required />
                            <input
                                    className='input-insert-product'
                                    type='text' 
                                    name='price'
                                    // onChange={this.typeInput}         
                                    placeholder='Preços' 
                                    required />
                            <div>
                                <input
                                    className='input-insert-product'
                                    type='text' 
                                    name='weight'
                                    // onChange={this.typeInput}         
                                    placeholder={t('products-table.weight')}
                                    required />
                                <input
                                    className='input-insert-product'
                                    type='text' 
                                    name='quantity'
                                    // onChange={this.typeInput}         
                                    placeholder={t('products-table.quantity')}
                                    required />
                            </div>
                            <div>
                                <input
                                    className='input-insert-product'
                                    type='text' 
                                    name='category'
                                    // onChange={this.typeInput}         
                                    placeholder={t('products-table.category')} 
                                    required />

                                <div className='toggles'>
                                    <p>{t('products-table.published')}</p>
                                    <ToggleButtonComponent/>
                                </div>
                            </div>
                        </div>
                        <div className='group-buttons'>
                            <button className='button-mass-action filter-color'>{t('buttons.filter')}</button>
                            <button className='button-mass-action save-color'>{t('buttons.save')}</button>
                            <button className='button-mass-action'>{t('buttons.clear')}</button>
                        </div>
                    </div>
                    <div className='container-table'>
                        <Table id='table' responsive>
                            <thead>
                                <tr>
                                    <th className='width-lg'>{t('products-table.name')} <ButtonFilterComponent/></th>
                                    <th className='width-s'>{t('products-table.weight')} <ButtonFilterComponent/></th>
                                    <th className='width-md'>{t('products-table.value')} <ButtonFilterComponent/></th>
                                    <th className='width-s'>{t('products-table.quantity')} <ButtonFilterComponent/></th>
                                    <th className='width-s'>{t('products-table.validate')} <ButtonFilterComponent/></th>
                                    <th className='width-s'>{t('products-table.published')} <ButtonFilterComponent/></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='table-tr-mass-action'>
                                    <td><img className='image-product' src='' alt='Imagem do produto' />Fralda Red - Las Moras. Temperatura: Congelado</td>
                                    <td>0.9kg</td>
                                    <td>R$ 320,00</td>
                                    <td>14</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>  
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><img className='image-product' src='' alt='Imagem do produto' />Picanha B 1,3 a 1,7kg - Frigochaco. Temperatura: Resfriado</td>
                                    <td>1.5kg</td>
                                    <td>R$ 600,00</td>
                                    <td>9</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='validate-green'></li>
                                                <li className='d-none validate-yellow'></li>
                                                <li className='validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Jerked Beef Traseiro 6x5kg - Frinense. Temperatura: Resfriado</td>
                                    <td>5kg</td>
                                    <td>R$ 840,00</td>
                                    <td>12</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='d-none validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='d-none validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Alcatra Completa Grill 7,5kg UP - Frialto. Temperatura: Resfriado</td>
                                    <td>8kg</td>
                                    <td>R$ 1000,00</td>
                                    <td>25</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='d-none validate-orange'></li>
                                                <li className='d-none validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Contra Filé Grill 5,0kg UP - Frialto. Temperatura: Resfriado</td>
                                    <td>5.5kg</td>
                                    <td>R$ 640,00</td>
                                    <td>15</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='validate-green'></li>
                                                <li className='d-none validate-yellow'></li>
                                                <li className='validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Picanha A até 1,4kg - J.A.. Temperatura: Resfriado</td>
                                    <td>4kg</td>
                                    <td>R$ 230,00</td>
                                    <td>5</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='d-none validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Picanha B - J.A.. Temperatura: Congelado</td>
                                    <td>9kg</td>
                                    <td>R$ 600,00</td>
                                    <td>3</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='d-none validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Contra filé 4,5kg UP (Chorizo)  - Gran Campeon  . Temperatura: Resfriado</td>
                                    <td>5kg</td>
                                    <td>R$ 420,00</td>
                                    <td>4</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='d-none validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='validate-orange'></li>
                                                <li className='d-none validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Músculo Traseiro - Gran Campeon  . Temperatura: Resfriado</td>
                                    <td>2kg</td>
                                    <td>R$ 130,00</td>
                                    <td>6</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='validate-green'></li>
                                                <li className='d-none validate-yellow'></li>
                                                <li className='d-none validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Osso Buco - Gran Campeon  . Temperatura: Congelado</td>
                                    <td>3.4kg</td>
                                    <td>R$ 170,00</td>
                                    <td>4</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='d-none validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='d-none validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Bife Ancho 2 kg UP - Guidara. Temperatura: Congelado</td>
                                    <td>9.5kg</td>
                                    <td>R$ 450,00</td>
                                    <td>7</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Coração da Alcatra - Guidara. Temperatura: Congelado</td>
                                    <td>6.5kg</td>
                                    <td>R$ 670,00</td>
                                    <td>13</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='validate-green'></li>
                                                <li className='d-none validate-yellow'></li>
                                                <li className='validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>French Rack 14/16 - Las Piedras. Temperatura: Congelado</td>
                                    <td>4.5kg</td>
                                    <td>R$ 590,50</td>
                                    <td>6</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                                <tr className='table-tr-mass-action'>
                                    <td><div><img className='image-product' src='' alt='Imagem do produto' /></div>Paleta de Cordeiro até 1,9kg - Las Piedras. Temperatura: Congelado</td>
                                    <td>4.5kg</td>
                                    <td>R$ 480,00</td>
                                    <td>12</td>
                                    <td>
                                        <div className='d-flex'>
                                            <ul className='validates-column'>
                                                <li className='d-none validate-green'></li>
                                                <li className='validate-yellow'></li>
                                                <li className='validate-orange'></li>
                                                <li className='validate-red'></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td><ToggleButtonComponent /></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </section>
            </>
            }
        />
    )
}

export default MassActionsScreen
